import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
function SemAccordion() {
  return (
    <>
      <Accordion preExpanded={["a"]}>
        <AccordionItem uuid="a">
          <AccordionItemHeading>
            <AccordionItemButton>Semester I</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
                <table>
              <tr>
                <th>Paper Code</th>
                <th>Title</th>
                <th>Credit</th>
                <th>Marks</th>
              </tr>

              <tr>
                <td>BCA F1001</td>
                <td>Business Communication</td>
                <td>4</td>
                <td>100</td>
              </tr>

              <tr>
                <td>BCA F1002</td>
                <td>Basic Mathematics-I</td>
                <td>5</td>
                <td>100</td>
              </tr>

              <tr>
                <td>BCA F1003</td>
                <td>Business Practices And Management</td>
                <td>4</td>
                <td>100</td>
              </tr>

              <tr>
                <td>BCA C1004</td>
                <td>Introduction to Computer Science </td>
                <td>4</td>
                <td>100</td>
              </tr>

              <tr>
                <td>BCA C1005</td>
                <td>Problem Solving and Programming in C</td>
                <td>4</td>
                <td>100</td>
              </tr>

              <tr>
                <td></td>
                <td><b>Sessional</b></td>
                <td></td>
                <td></td>
              </tr>

              

              <tr>
                <td>BCA P1006</td>
                <td>Computer Basics and PC Software Lab</td>
                <td>1</td>
                <td>50</td>
              </tr>

              <tr>
                <td>BCA P1007</td>
                <td>C Programming Lab</td>
                <td>1</td>
                <td>50</td>
              </tr>

              <tr>
                <td>BCA P1008</td>
                <td>Communication Skill Lab</td>
                <td>1</td>
                <td>50</td>
              </tr>
              </table>

            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem uuid="b">
          <AccordionItemHeading>
            <AccordionItemButton>Semester II</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
                <table>
              <tr>
                <th>Paper Code</th>
                <th>Title</th>
                <th>Credit</th>
                <th>Marks</th>
              </tr>

              <tr>
                <td>BCA F2001</td>
                <td>Basic Mathematics II </td>
                <td>4</td>
                <td>100</td>
              </tr>

              <tr>
                <td>BCA F2002</td>
                <td>Environmental Science </td>
                <td>4</td>
                <td>100</td>
              </tr>

              <tr>
                <td>BCA C2003</td>
                <td>Database Management System </td>
                <td>5</td>
                <td>100</td>
              </tr>

              <tr>
                <td>BCA C2004</td>
                <td>Object Oriented Programming using C++</td>
                <td>5</td>
                <td>100</td>
              </tr>

              <tr>
                <td>BCA C2005</td>
                <td>Logic Design </td>
                <td>4</td>
                <td>100</td>
              </tr>

              <tr>
                <td></td>
                <td><b>Sessional</b></td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <td>BCA P2006</td>
                <td>C ++ Programming Lab</td>
                <td>1</td>
                <td>50</td>
              </tr>

              <tr>
                <td>BCA P2007</td>
                <td>Database Management System Lab</td>
                <td>1</td>
                <td>50</td>
              </tr>

              <tr>
                <td>BCA P2008</td>
                <td>Circuit Design Lab</td>
                <td>1</td>
                <td>50</td>
              </tr>
              </table>
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem uuid="c">
          <AccordionItemHeading>
            <AccordionItemButton>Semester III</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
                <table>
              <tr>
                <th>Paper Code</th>
                <th>Title</th>
                <th>Credit</th>
                <th>Marks</th>
              </tr>

              <tr>
                <td>BCA C3001</td>
                <td>Data Structure using C</td>
                <td>5</td>
                <td>100</td>
              </tr>

              <tr>
                <td>BCA C3002</td>
                <td>Java Programming</td>
                <td>4</td>
                <td>100</td>
              </tr>

              <tr>
                <td>BCA C3003</td>
                <td>Computer Architecture</td>
                <td>5</td>
                <td>100</td>
              </tr>

              <tr>
                <td>BCA C3004</td>
                <td>System Analysis and Design</td>
                <td>4</td>
                <td>100</td>
              </tr>

              <tr>
                <td>BCA C3005</td>
                <td>Probability and Statistics</td>
                <td>4</td>
                <td>100</td>
              </tr>

              <tr>
                <td></td>
                <td><b>Sessional</b></td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <td>BCA P3006</td>
                <td>Data Structure Lab</td>
                <td>1</td>
                <td>50</td>
              </tr>

              <tr>
                <td>BCA P3007</td>
                <td>Java Programming Lab</td>
                <td>1</td>
                <td>50</td>
              </tr>

              <tr>
                <td>BCA P3008</td>
                <td>Statistical Lab</td>
                <td>1</td>
                <td>50</td>
              </tr>
              </table>
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem uuid="d">
          <AccordionItemHeading>
            <AccordionItemButton>Semester IV</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
                <table>
              <tr>
                <th>Paper Code</th>
                <th>Title</th>
                <th>Credit</th>
                <th>Marks</th>
              </tr>

              <tr>
                <td>BCA C4001</td>
                <td>Multimedia</td>
                <td>4</td>
                <td>100</td>
              </tr>

              <tr>
                <td>BCA C4002</td>
                <td>Operating System</td>
                <td>5</td>
                <td>100</td>
              </tr>

              <tr>
                <td>BCA C4003</td>
                <td>HTML</td>
                <td>4</td>
                <td>100</td>
              </tr>

              <tr>
                <td>BCA C4004</td>
                <td>Visual Programming</td>
                <td>4</td>
                <td>100</td>
              </tr>

              <tr>
                <td>BCA C4005</td>
                <td>Computer Networks</td>
                <td>5</td>
                <td>100</td>
              </tr>

              <tr>
                <td></td>
                <td><b>Sessional</b></td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <td>BCA P4006</td>
                <td>Multimedia Lab</td>
                <td>1</td>
                <td>50</td>
              </tr>

              <tr>
                <td>BCA P4007</td>
                <td>Visual Programming Lab</td>
                <td>1</td>
                <td>50</td>
              </tr>

              <tr>
                <td>BCA P4008</td>
                <td>HTML Lab </td>
                <td>1</td>
                <td>50</td>
              </tr>
              </table>
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem uuid="e">
          <AccordionItemHeading>
            <AccordionItemButton>Semester V</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
                <table>
              <tr>
                <th>Paper Code</th>
                <th>Title</th>
                <th>Credit</th>
                <th>Marks</th>
              </tr>

              <tr>
                <td>BCA C5001</td>
                <td>Internet Concept and Web Design</td>
                <td>4</td>
                <td>100</td>
              </tr>

              <tr>
                <td>BCA C5002</td>
                <td>Design and Analysis of Algorithms</td>
                <td>5</td>
                <td>100</td>
              </tr>

              <tr>
                <td>BCA C5003</td>
                <td>Linux Programming</td>
                <td>5</td>
                <td>100</td>
              </tr>

              <tr>
                <td>BCA C5004</td>
                <td>Computer Oriented Numerical Methods</td>
                <td>4</td>
                <td>100</td>
              </tr>

              <tr>
                <td></td>
                <td>Elective - I</td>
                <td>4</td>
                <td>100</td>
              </tr>

              <tr>
                <td></td>
                <td><b>Sessional</b></td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <td>BCA P5005</td>
                <td>Internet Concept and Web Design Lab</td>
                <td>1</td>
                <td>50</td>
              </tr>

              <tr>
                <td>BCA P5006</td>
                <td>Numerical Method Lab</td>
                <td>1</td>
                <td>50</td>
              </tr>

              <tr>
                <td>BCA P5007</td>
                <td>Linux Programming Lab</td>
                <td>1</td>
                <td>50</td>
              </tr>
              </table>
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem uuid="f">
          <AccordionItemHeading>
            <AccordionItemButton>Semester VI</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
                <table>
              <tr>
                <th>Paper Code</th>
                <th>Title</th>
                <th>Credit</th>
                <th>Marks</th>
              </tr>

              <tr>
                <td>BCA C6001</td>
                <td>Optimization Techniques</td>
                <td>4</td>
                <td>100</td>
              </tr>

              <tr>
                <td>BCA C6002</td>
                <td>Principle of Management</td>
                <td>4</td>
                <td>100</td>
              </tr>

              <tr>
                <td>BCA C6003</td>
                <td>Accounting and Financial Management</td>
                <td>5</td>
                <td>100</td>
              </tr>

              <tr>
                <td>BCA C6004</td>
                <td>Network Security</td>
                <td>5</td>
                <td>100</td>
              </tr>

              <tr>
                <td>BCA C6005</td>
                <td>Elective – II</td>
                <td>4</td>
                <td>100</td>
              </tr>

              <tr>
                <td></td>
                <td><b>Sessional</b></td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <td>BCA P6005</td>
                <td>Project</td>
                <td>2</td>
                <td>100</td>
              </tr>

              <tr>
                <td>BCA P6006</td>
                <td>TALLY Lab</td>
                <td>1</td>
                <td>50</td>
              </tr>

              <tr>
                <td>BCA F1001</td>
                <td>Business Communication</td>
                <td>4</td>
                <td>100</td>
              </tr>
              </table>
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        
        <AccordionItem uuid="g">
          <AccordionItemHeading>
            <AccordionItemButton>Elective - I</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
                <table>
              <tr>
                <th>Paper Code</th>
                <th>Title</th>
                <th>Credit</th>
                <th>Marks</th>
              </tr>

              <tr>
                <td>BCA E5007</td>
                <td>E-COMMERECE </td>
                <td>4</td>
                <td>100</td>
              </tr>

              <tr>
                <td>BCA E5008</td>
                <td>SOFTWARE TESTING</td>                
                <td>4</td>
                <td>100</td>
              </tr>

            
              </table>
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem uuid="h">
          <AccordionItemHeading>
            <AccordionItemButton>Elective - II</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
                <table>
              <tr>
                <th>Paper Code</th>
                <th>Title</th>
                <th>Credit</th>
                <th>Marks</th>
              </tr>

              <tr>
                <td>BCA E6006</td>
                <td>MANAGEMENT INFORMATION SYSTEM</td>
                <td>4</td>
                <td>100</td>
              </tr>

              <tr>
                <td>BCA E6007</td>
                <td>ARTIFICIAL INTELLIGENCE</td>
                <td>4</td>
                <td>100</td>
              </tr>

             
              </table>
            </p>
          </AccordionItemPanel>
        </AccordionItem>


      </Accordion>
    </>
  );
}

export default SemAccordion;
