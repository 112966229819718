import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";

const responsive = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 4000, min: 3000 },
		items: 5,
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 4,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 3,
	},
};

function GalleryCarousel() {
	const gotoTop = () => {
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
	};
	return (
		<>
			<div className="GalleryCarouselContainer" data-aos="fade-up">
				<div id="Heading" data-aos="fade-up">
					<h1>Gallery</h1>
				</div>

				<Link
					to="/gallery"
					onClick={() => {
						gotoTop();
					}}
				>
					<Carousel
						swipeable={true}
						draggable={false}
						showDots={false}
						responsive={responsive}
						ssr={true} // means to render carousel on server-side.
						infinite
						pauseOnHover
						autoPlay
						// autoPlay={this.props.deviceType !== "mobile" ? true : false}
						autoPlaySpeed={2000}
						keyBoardControl={true}
						// customTransition="all 1.5"
						transitionDuration={500}
						containerClass="carousel-container"
						removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
						// deviceType={this.props.deviceType}
						dotListClass="custom-dot-list-style"
						itemClass="carousel-item-padding-40-px"
					>
						<div className="GalleryCarouselCard" data-aos="fade-up">
							<div className="GalleryImg">
								<img src="/Images/Campus/Campus/campus-4.jpg" alt="campus" />
							</div>
							<div className="GalleryCarouselTitle">
								<h6>Campus Life</h6>
							</div>
						</div>

						<div className="GalleryCarouselCard" data-aos="fade-up">
							<div className="GalleryImg">
								<img src="/Images/Campus/Dept/lab-1.jpg" alt="gallery-img" />
							</div>
							<div className="GalleryCarouselTitle">
								<h6>Lab</h6>
							</div>
						</div>

						{/* <div className="GalleryCarouselCard" data-aos="fade-up">
            <div className="GalleryImg">
              <img
                src= "/Images/Campus/campus5.jpg"
              />
            </div>
            <div className="GalleryCarouselTitle">
              <h6>Campus Life</h6>
            </div>
          </div> */}

						<div className="GalleryCarouselCard" data-aos="fade-up">
							<div className="GalleryImg">
								<img src="/Images/Campus/Dept/class1.jpg" alt="gallery-img" />
							</div>
							<div className="GalleryCarouselTitle">
								<h6>Class Room</h6>
							</div>
						</div>

						<div className="GalleryCarouselCard" data-aos="fade-up">
							<div className="GalleryImg">
								<img
									src="/Images/Campus/Dept/library-2.jpg"
									alt="gallery-img"
								/>
							</div>
							<div className="GalleryCarouselTitle">
								<h6>Library</h6>
							</div>
						</div>

						<div className="GalleryCarouselCard" data-aos="fade-up">
							<div className="GalleryImg">
								<img src="/Images/Campus/Event/event-1.jpg" alt="gallery-img" />
							</div>
							<div className="GalleryCarouselTitle">
								<h6>Events</h6>
							</div>
						</div>
					</Carousel>
				</Link>
			</div>
		</>
	);
}

export default GalleryCarousel;
