import React from "react";
import { Helmet } from "react-helmet";

function SolarPower() {
  return (
		<>
			<Helmet>
				<title>VBU-Solar Campus</title>
			</Helmet>
			<div className="PageContainer">
				<div className="Campus">
					<div className="BannerImg HeaderImg">
						<img src="/Images/Campus/Campus/solar.jpg" alt="banenr-img" />
					</div>

					<div id="Heading">
						<h1>Solar Power Campus</h1>
					</div>

					<div className="Desc">
						<div className="DescParagraph">
							<p>
								Sure, here is a short note on solar power campus of college
								university: College and university campuses are increasingly
								turning to solar power as a way to reduce their reliance on
								fossil fuels and lower their carbon emissions. Solar power is a
								clean, renewable energy source that can help colleges and
								universities achieve their sustainability goals.
								<br />
								<br />
								Despite these challenges, the benefits of solar power for
								college and university campuses outweigh the costs. Solar power
								is a clean, renewable energy source that can help colleges and
								universities achieve their sustainability goals.
							</p>
						</div>
						<div className="DescImg">
							<img src="/Images/Campus/Campus/solar-1.jpg" alt="img1" />
						</div>
					</div>

					<div className="PhotosSection">
						<div id="Secondary_Heading">
							<h3>Photos</h3>
						</div>

						<div className="PhotoContainer">
							<div className="PhotoRow">
								<div className="Img1">
									<img
										src="/Images/Campus/Campus/solar-6.jpg"
										alt="imgdinner"
									/>
								</div>
								<div className="Img2">
									<img
										src="/Images/Campus/Campus/solar-2.jpg"
										alt="imgdinner"
									/>
								</div>
							</div>
							<div className="PhotoRow">
								<div className="Img1">
									<img
										src="/Images/Campus/Campus/solar-4.jpg"
										alt="imgdinner"
									/>
								</div>
								<div className="Img2">
									<img
										src="/Images/Campus/Campus/solar-3.jpg"
										alt="imgdinner"
									/>
								</div>
							</div>
							<div className="PhotoRow">
								<div className="Img3">
									<img
										src="/Images/Campus/Campus/solar-5.jpg"
										alt="imgdinner"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default SolarPower;
