import React, { useEffect, useRef, useState } from "react";
import HeroSection from "../Components/HeroSection";
import MessageToStudent from "../Components/MessageToStudent";
import LatestUpdates from "../Components/LatestUpdates";
import Courses from "../Components/Courses";
import GalleryCarousel from "../Components/GalleryCarousel";
import Slogan from "../Components/Slogan";
import Notices from "../notice.json";
import axios from "axios";
import { Helmet } from "react-helmet";

function Home() {
  const [notices, setNotices] = useState([]);
  useEffect(() => {
    axios
      .post("/api/notices/getNoticeListClient", {
        pageno: "1",
      })
      .then((res) => {
        setNotices(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const scrollRef = useRef(null);
  useEffect(() => {
    const scrollElement = scrollRef.current;
    const scrollWidth = scrollElement.scrollWidth;
    const containerWidth = scrollElement.clientWidth;
    const totalWidth = scrollWidth + containerWidth;

    // Set animation duration based on total width
    const duration = totalWidth / 100; // Adjust this value to control speed
    scrollElement.style.animationDuration = `${duration}s`;
  }, [notices]);
  return (
    <>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <main>
        <HeroSection />
        <section>
          <div className="NoticeScroll" data-aos="fade-down">
            <span className="latestUpdates">
              <h5>Latest Updates :</h5>{" "}
            </span>

            <span
              className="scroll"
              ref={scrollRef}
              style={{ display: "flex" }}
            >
              {notices === undefined ||
                notices?.map((item) => {
                  return (
                    <span className="noticeItem" key={item.id}>
                      {new Date(item.updated_at).toDateString()}&nbsp; - &nbsp;
                      {item.title}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  );
                })}
            </span>
          </div>
        </section>
        <div className="HomePageContainer">
          <MessageToStudent />
          <LatestUpdates />
          <Courses />
          <GalleryCarousel />
          <Slogan />
        </div>
      </main>
    </>
  );
}

export default Home;
