import React from "react";
import {GiCheckMark} from "react-icons/gi";
import ResearchForm from "../Components/ResearchForm";
import ResearchList from "../Components/ResearchList";
import { Helmet } from "react-helmet";

function Research() {
  return (
		<>
			<Helmet>
				<title>Research</title>
			</Helmet>
			<div className="PageContainer">
				<div className="ResearchHeader HeaderImg">
					<img src="/Images/Banner/research.jpg" alt="banner" />
				</div>
				<div id="Heading" className="CourseTitle" data-aos="fade-up">
					<h1>Research Facility</h1>
				</div>
				<div className="ResearchDesc">
					<p data-aos="fade-up">
						Research Facilities provided by the institution are constantly
						updated and upgraded to foster and promote Research Culture in the
						campus.
						<br />
						<br />
						The institution offers the following research facilities for the
						Faculty/Student Researchers:
						<br />
						<br />
						Apart from extending assistance as a facilitator in sourcing funds
						from national/international/govt/non-govt/local agencies, the
						Management also provides seed money for the Research Projects:
						Doctoral, Major or Minor – processed by the research committee and
						the principal.
						<br />
						<br />
						The management provides an environment conducive to research by
						providing suitable infrastructural facilities such as space,
						State-of -the art Library as knowledge resource centre ,Lab
						facilities with an access to INFLIBNET, DELNET, SPSS software,
						professional guidance and recommendation for financial assistance
						from National/International/Govt/ Non-Govt/ Industry and Local
						agencies.
					</p>
					<br />
					<br />
					<ResearchForm />
					<ResearchList />
					<p data-aos="fade-up">
						<b>
							Organizes faculty development programmes, conferences, workshops
							on Research Methodology to promote research culture .
						</b>
					</p>
					<br />

					<p>
						<tr data-aos="fade-up">
							<td>
								<GiCheckMark className="check-icon" />
							</td>
							<td>promote Major and Minor Research projects</td>
						</tr>
						<tr data-aos="fade-up">
							<td>
								<GiCheckMark className="check-icon" />
							</td>
							<td>
								To incentivize the faculty for paper publications in Journals of
								National/international repute and also on receiving national and
								international recognition or awards
							</td>
						</tr>
						<tr data-aos="fade-up">
							<td>
								<GiCheckMark className="check-icon" />
							</td>
							<td>
								To grant Sabbatical leaves, study leaves, seed money, flexi
								timings, ODI, reduction in workload for the faculty pursuing
								research
							</td>
						</tr>
						<tr data-aos="fade-up">
							<td>
								<GiCheckMark className="check-icon" />
							</td>
							<td>
								To create an ecosystem for innovations including Incubation
								centre and other initiatives for creation and transfer of
								knowledge
							</td>
						</tr>
						<tr data-aos="fade-up">
							<td>
								<GiCheckMark className="check-icon" />
							</td>
							<td>
								Participation in National/ International conferences, paper
								presentations and publications will come under the purview of
								Research policy.
							</td>
						</tr>
						<tr data-aos="fade-up">
							<td>
								<GiCheckMark className="check-icon" />
							</td>
							<td>
								Guidance and financial assistance for students to present and
								publish articles in National/International Conferences/Seminars.
							</td>
						</tr>
						<tr data-aos="fade-up">
							<td>
								<GiCheckMark className="check-icon" />
							</td>
							<td>
								To create awareness on ethical and legal issues pertaining to
								plagiarism
							</td>
						</tr>
					</p>
					<br />

					<h3 data-aos="fade-up">
						Research Promotion Policy and Research facilities
					</h3>
					<ol type="1" data-aos="fade-up">
						The Research Committee of the college shall be responsible for
						implementing the research policy of the college by working closely
						with the college management. <br />
						<li data-aos="fade-up">
							Facilitates the faculty in undertaking research and will work with
							the college management to set up a research fund for providing
							seed money
						</li>
						<li data-aos="fade-up">
							Provide research facilities in terms of laboratory equipment,
							research journals and research incentives etc. required by the
							faculty and improve the availability of research infrastructure
						</li>
						<li data-aos="fade-up">
							Encourage and promote research culture (e.g. teaching work load
							remission, opportunities for attending conferences etc.)
						</li>
						<li data-aos="fade-up">
							Encourage the faculty to undertake research by collaborating with
							other research organizations / industry and organize workshops/
							training programmes/ sensitization programmes to promote research
							culture.
						</li>
						<li data-aos="fade-up">
							Prepare budgets for facilitating staff/students ‘research
							projects.
						</li>
					</ol>
				</div>
			</div>
		</>
	);
}

export default Research;
