import React from "react";
import { Helmet } from "react-helmet";

function DigitalClass() {
  return (
		<>
			<Helmet>
				<title>MCA-ClassRoom</title>
			</Helmet>
			<div className="PageContainer">
				<div className="Campus">
					<div className="BannerImg HeaderImg">
						<img src="/Images/Campus/Canteen/frontpic.png" alt="banenr-img" />
					</div>

					<div id="Heading">
						<h1>Digital Classroom</h1>
					</div>

					<div className="Desc">
						<div className="DescParagraph">
							<p>
								A digital classroom is a classroom that uses technology to
								enhance student learning. It may be a physical classroom that is
								equipped with computers, projectors, and other technology, or it
								may be an online classroom that is delivered through a virtual
								learning platform.Digital classrooms are becoming increasingly
								common in colleges and universities. They offer a number of
								benefits for students, and they are likely to become even more
								widespread in the future.
								<br />
								<br />
								Digital classrooms can be a great way to enhance student
								learning. However, it is important to note that they are not a
								magic bullet. They still require careful planning and
								implementation in order to be effective.
							</p>
						</div>
						<div className="DescImg">
							<img src="/Images/Campus/Canteen/drinks.png" alt="img1" />
						</div>
					</div>

					<div className="PhotosSection">
						<div id="Secondary_Heading">
							<h3>Photos</h3>
						</div>

						<div className="PhotoContainer">
							<div className="PhotoRow">
								<div className="Img1">
									<img
										src="/Images/Campus/Canteen/dinner.png"
										alt="imgdinner"
									/>
								</div>
								<div className="Img2">
									<img
										src="/Images/Campus/Canteen/drinks.png"
										alt="imgdinner"
									/>
								</div>
							</div>
							<div className="PhotoRow">
								<div className="Img1">
									<img src="/Images/Campus/Canteen/lunch.png" alt="imgdinner" />
								</div>
								<div className="Img2">
									<img
										src="/Images/Campus/Canteen/breakfast.png"
										alt="imgdinner"
									/>
								</div>
							</div>
							<div className="PhotoRow">
								<div className="Img3">
									<img
										src="/Images/Campus/Canteen/snacks.png"
										alt="imgdinner"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default DigitalClass;
