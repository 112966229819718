import React from "react";
import { RiDoubleQuotesL, RiDoubleQuotesR } from "react-icons/ri";

function Slogan() {
  return (
    <>
      <div className="SloganContainer" data-aos="fade-up">
        <div className="Slogan">
          <h4>तुलसीदास</h4>
          <p>
            <span style={{margin:"0px 7px"}}>
              <RiDoubleQuotesL size={"20px"} />
            </span>
            विद्या ददाति विनयं विनयो ददाति पात्रताम् ।<br />
            पात्रता ददाति धनं धनं ददाति निर्भयम् । <br />
            निर्भयः सर्वदा सुखी ॥
            <span style={{margin:"0px 5px"}}>
              <RiDoubleQuotesR size={"20px"} />
            </span>
          </p>
        </div>
      </div>
    </>
  );
}

export default Slogan;
