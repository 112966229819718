import { React, useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { FaDownload } from "react-icons/fa";
import { BsPinFill } from "react-icons/bs";
import Aos from "aos";
import "aos/dist/aos.css";

function LatestUpdates() {
  const [notices, setNotices] = useState([]);
  useEffect(() => {
    Aos.init({ duration: 1200 });
  }, []);

  useEffect(() => {
    axios
      .post("/api/notices/getNoticeListClient", {
        pageno: "1",
      })
      .then((res) => {
        setNotices(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <div className="LatestUpdatesContainer">
        {/* <div className="top"> */}
        <div id="Heading" data-aos="fade-up">
          <h1>Latest Updates</h1>
          {/* </div> */}
        </div>

        <div className="NoticeContainer" data-aos="fade-up">
          {notices === undefined ||
            notices?.map((notice) => {
              console.log(notice);
              return (
                <Link
                  to={"https://api.vbumca.in" + notice.file_location}
                  target="_blank"
                >
                  <div className="NoticeCard" data-aos="fade-up">
                    <div className="NoticeCardLeft">
                      <div className="NoticeTitle">
                        <span className="pin-icon">
                          <BsPinFill />
                        </span>
                        <span>{notice.title}</span>
                      </div>
                      <div className="NoticeDate">
                        <span>
                          {new Date(notice.updated_at).toDateString()}
                        </span>
                      </div>
                    </div>
                    <div className="NoticeCardRight">
                      <span className="download-icon">
                        <FaDownload />
                      </span>
                    </div>
                  </div>
                </Link>
              );
            })}
          <div className="viewAll" data-aos="fade-up">
            {/* console.log(notices) */}
            {notices === undefined ? "" : <Link to="/allNotice">View All</Link>}
          </div>
        </div>
      </div>
    </>
  );
}

export default LatestUpdates;
