import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
function SemAccordion() {
  return (
    <>
      <Accordion preExpanded={["a"]}>
        <AccordionItem uuid="a">
          <AccordionItemHeading>
            <AccordionItemButton>Semester I</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
                <table>
              <tr>
                <th>Paper Code</th>
                <th>Title</th>
                <th>Credit</th>
                <th>Marks</th>
              </tr>

              <tr>
                <td>MCA C1001</td>
                <td>Fundamentals of Information Technology	</td>
                <td>4</td>
                <td>100</td>
              </tr>

              <tr>
                <td>MCA C1002</td>
                <td>Data Structures through C</td>
                <td>4</td>
                <td>100</td>
              </tr>

              <tr>
                <td>MCA C1003</td>
                <td>Operating System Concepts</td>
                <td>4</td>
                <td>100</td>
              </tr>

              <tr>
                <td>MCA C1004</td>
                <td>Database Management System </td>
                <td>4</td>
                <td>100</td>
              </tr>

              <tr>
                <td>MCA C1005</td>
                <td>Computer Organization & Architecture</td>
                <td>4</td>
                <td>100</td>
              </tr>

              <tr>
                <td>MCA C1006</td>
                <td>Management Information System </td>
                <td>4</td>
                <td>100</td>
              </tr>

              <tr>
                <td></td>
                <td><b>Sessional</b></td>
                <td></td>
                <td></td>
              </tr>

              

              <tr>
                <td>MCA P1007</td>
                <td>Fundamentals of Computers Lab</td>
                <td>2</td>
                <td>50</td>
              </tr>

              <tr>
                <td>MCA P1008</td>
                <td>Data Structures Lab </td>
                <td>2</td>
                <td>50</td>
              </tr>

              <tr>
                <td>MCA P1009</td>
                <td>Database Management System Lab</td>
                <td>2</td>
                <td>50</td>
              </tr>
              </table>

            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem uuid="b">
          <AccordionItemHeading>
            <AccordionItemButton>Semester II</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
                <table>
              <tr>
                <th>Paper Code</th>
                <th>Title</th>
                <th>Credit</th>
                <th>Marks</th>
              </tr>

              <tr>
                <td>MCA C2001</td>
                <td>Advance Java Programming</td>
                <td>4</td>
                <td>100</td>
              </tr>

              <tr>
                <td>MCA C2002</td>
                <td>Design and Analysis of Algorithms</td>
                <td>4</td>
                <td>100</td>
              </tr>

              <tr>
                <td>MCA C2003</td>
                <td>Data Communication & Computer Networks</td>
                <td>4</td>
                <td>100</td>
              </tr>

              <tr>
                <td>MCA C2004</td>
                <td>Discrete Mathematics </td>
                <td>4</td>
                <td>100</td>
              </tr>

              <tr>
                <td>MCA C2005</td>
                <td>Software Engineering  </td>
                <td>4</td>
                <td>100</td>
              </tr>
              <tr>
                <td></td>
                <td>Elective- I   </td>
                <td>4</td>
                <td>100</td>
              </tr>

              <tr>
                <td></td>
                <td><b>Sessional</b></td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <td>MCA P2006</td>
                <td>Advance Java Lab</td>
                <td>2</td>
                <td>50</td>
              </tr>

              <tr>
                <td>MCA P2007</td>
                <td>Algorithms Lab</td>
                <td>2</td>
                <td>50</td>
              </tr>

              <tr>
                <td>MCA P2008</td>
                <td>Computer Networks Lab</td>
                <td>2</td>
                <td>50</td>
              </tr>
              </table>
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem uuid="c">
          <AccordionItemHeading>
            <AccordionItemButton>Semester III</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
                <table>
              <tr>
                <th>Paper Code</th>
                <th>Title</th>
                <th>Credit</th>
                <th>Marks</th>
              </tr>

              <tr>
                <td>MCA C3001</td>
                <td>Data Warehousing & Data Mining </td>
                <td>4</td>
                <td>100</td>
              </tr>

              <tr>
                <td>MCA C3002</td>
                <td>Compiler Design </td>
                <td>4</td>
                <td>100</td>
              </tr>

              <tr>
                <td>MCA C3003</td>
                <td>Programming in Python</td>
                <td>4</td>
                <td>100</td>
              </tr>

              <tr>
                <td>MCA C3004</td>
                <td>Soft Computing</td>
                <td>4</td>
                <td>100</td>
              </tr>

              <tr>
                <td>MCA C3005</td>
                <td>Financial Accounting</td>
                <td>4</td>
                <td>100</td>
              </tr>
              <tr>
                <td></td>
                <td>Elective –II</td>
                <td>4</td>
                <td>100</td>
              </tr>

              <tr>
                <td></td>
                <td><b>Sessional</b></td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <td>MCA P3006</td>
                <td>Python Lab </td>
                <td>2</td>
                <td>50</td>
              </tr>

              <tr>
                <td>MCA P3007</td>
                <td>Tally Lab</td>
                <td>2</td>
                <td>50</td>
              </tr>

              <tr>
                <td>MCA P3008</td>
                <td>Mini Project/ Practical Training</td>
                <td>2</td>
                <td>50</td>
              </tr>
              </table>
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem uuid="d">
          <AccordionItemHeading>
            <AccordionItemButton>Semester IV</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
                <table>
              <tr>
                <th>Paper Code</th>
                <th>Title</th>
                <th>Credit</th>
                <th>Marks</th>
              </tr>

              <tr>
                <td>MCA C4001</td>
                <td>Computer Graphics</td>
                <td>4</td>
                <td>100</td>
              </tr>

              <tr>
                <td>MCA C4002</td>
                <td>Operations Research</td>
                <td>4</td>
                <td>100</td>
              </tr>

             


              <tr>
                <td></td>
                <td><b>Sessional</b></td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <td>MCA P4003</td>
                <td>Personality Development</td>
                <td>2</td>
                <td>50</td>
              </tr>

              <tr>
                <td>MCA P4004</td>
                <td>Android Application Development</td>
                <td>2</td>
                <td>50</td>
              </tr>

              <tr>
                <td>MCA P4005</td>
                <td><b>Major Project </b></td>
                <td>18</td>
                <td>450</td>
              </tr>
              </table>
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem uuid="e">
          <AccordionItemHeading>
            <AccordionItemButton>Elective- I</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
                <table>
              <tr>
                <th>Paper Code</th>
                <th>Title</th>
                <th>Credit</th>
                <th>Marks</th>
              </tr>

              <tr>
                <td>MCA E2009 </td>
                <td>Cyber Security</td>
                <td>4</td>
                <td>100</td>
              </tr>

              <tr>
                <td>MCA E2010</td>
                <td>Artificial Intelligence</td>
                <td>4</td>
                <td>100</td>
              </tr>

              <tr>
                <td>MCA E2011</td>
                <td>Cloud Computing</td>
                <td>4</td>
                <td>100</td>
              </tr>

           
              </table>
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem uuid="f">
          <AccordionItemHeading>
            <AccordionItemButton>Elective - II</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
                <table>
              <tr>
                <th>Paper Code</th>
                <th>Title</th>
                <th>Credit</th>
                <th>Marks</th>
              </tr>

              <tr>
                <td>MCA E3009</td>
                <td>Big Data Analytics</td>
                <td>4</td>
                <td>100</td>
              </tr>

              <tr>
                <td>MCA E3010</td>
                <td>Software Testing</td>
                <td>4</td>
                <td>100</td>
              </tr>

              <tr>
                <td>MCA E3011</td>
                <td>Machine Learning </td>
                <td>4</td>
                <td>100</td>
              </tr>

             
              </table>
            </p>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
}

export default SemAccordion;
