import React, { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";

function AlumniLIst() {
	const [alumniList, setAlumniList] = useState([]);
	useEffect(() => {
		axios
			.get("/api/alumini/getAllClient", {})
			.then((res) => {
				setAlumniList(res.data.Data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	return (
		<>
			<Helmet>
				<title>Our Alumni</title>
			</Helmet>
			<div className="PageContainer">
				<div className="AlumniHeader HeaderImg ">
					<img src="/Images/Banner/alumni.jpg" alt="banner" />
				</div>

				{/* Alumni Table  */}
				<div className="AlumniTableContainer">
					<div className="AlumniHeading" >
						<div>
							<h3>Our Alumni</h3>
							<span className="border-bottom"></span>
						</div>
					</div>

					<div className="AlumniTable">
						<table>
							<thead>
								<tr>
									<th>Profile</th>
									<th>Name</th>
									<th>Company</th>
									<th>Designation</th>
									<th>Course</th>
									<th>Passing Year</th>
									<th>LinkedIn</th>
								</tr>
							</thead>
							{/* <tbody> */}
							{alumniList === undefined ||
								alumniList.map((item, index) => {
									return (
										<>
											{console.log(item)}
											{alumniList === undefined ? (
												<p>NO Record</p>
											) : (
												<tr key={index}>
													<td>
														<img
															src={
																"https://api.vbumca.in/storage/app/public/" +
																item.photo_location
															}
															alt="profile"
														/>
													</td>
													<td>{item.name}</td>
													<td>{item.company}</td>
													<td>{item.designation}</td>
													<td>{item.course}</td>
													<td>{item.passing_year}</td>
													<td>
														<a href={item.linkedin}>
															<b>Link</b>
														</a>
													</td>
												</tr>
											)}
										</>
									);
								})}
							{/* </tbody> */}
						</table>
					</div>
				</div>
			</div>
		</>
	);
}

export default AlumniLIst;
