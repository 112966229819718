import React, { useState } from "react";
import { Helmet } from "react-helmet";

import CoursesData from "../content.json";
// import { BsLightningFill } from "react-icons/gi";
import { BsLightningFill } from "react-icons/bs";
import Courses from "../Components/Courses";
import SemAccordion from "../Components/SemAccordion";
function BCA() {
  const [BCAData, SetBCAData] = useState(CoursesData.Courses[0]);
  return (
		<>
			<Helmet>
				<title>BCA</title>
			</Helmet>
			<div className="PageContainer">
				<div className="">
					<div className="CourseBanner HeaderImg">
						<img src={BCAData.course_banner} alt="BCA-banner" />
					</div>

					<div id="Heading" className="CourseTitle" data-aos="fade-up">
						<h1>{BCAData.course_fullname}</h1>
					</div>
					<br />
					<p className="CourseDesc" data-aos="fade-up">
						{BCAData.course_desc}
					</p>
					<br />

					<div className="CourseDetailsContainer">
						<div id="Secondary_Heading" data-aos="fade-up">
							<h3>Course Detail</h3>
						</div>
						<div className="CourseTable" data-aos="fade-up">
							<table>
								<tr data-aos="fade-up">
									<td>Course -</td>
									<td>{BCAData.course_fullname}</td>
								</tr>
								<tr data-aos="fade-up">
									<td>Certificate -</td>
									<td>{BCAData.course_certification}</td>
								</tr>
								{/* <tr data-aos="fade-up">
                  <td>Shift -</td>
                  <td>{BCAData.course_shift}</td>
                </tr> */}
								<tr data-aos="fade-up">
									<td>Duration -</td>
									<td>{BCAData.course_duration}</td>
								</tr>
								<tr data-aos="fade-up">
									<td>No. of Semester -</td>
									<td>{BCAData.course_sem}</td>
								</tr>
								<tr data-aos="fade-up">
									<td>Seats -</td>
									<td>{BCAData.course_seat}</td>
								</tr>
								<tr data-aos="fade-up">
									<td>Eligibility -</td>
									<td>{BCAData.course_eligibility}</td>
								</tr>
								<tr data-aos="fade-up">
									<td>Fees -</td>
									<td>{BCAData.course_fee_per_sem}</td>
								</tr>
								<tr data-aos="fade-up">
									<td>University -</td>
									<td>{BCAData.university}</td>
								</tr>
							</table>
						</div>
					</div>

					<div className="CourseDetailsProcess" data-aos="fade-up">
						<div className="ElegibilityCriteria" data-aos="fade-up">
							<div className="SubHeading">
								<h4>Elegibility Criteria</h4>
							</div>
							<ul>
								<li>
									<BsLightningFill className="check-icon" />
									Any students who have passed Intermediate Science, Arts &
									Commerce with mathematics/Business Mathematics/Computer
									Science/Information Practices(I.P.) as one of the subjects.
									And having minimum 45% marks are eligible to take admission in
									this course.
								</li>
								<li>
									<BsLightningFill className="check-icon" />
									Final year students in respective qualifying examinations
									awaiting results can also apply.
								</li>
							</ul>
						</div>

						<div className="SelectionProcedure" data-aos="fade-up">
							<div className="SubHeading">
								<h4>Selection Procedure </h4>
							</div>
							<ul>
								<li>
									<BsLightningFill className="check-icon" />
									Students for the admission in BCA will be selected through
									Entrance Aptitude Test followed by counseling conducted by the
									Department.
								</li>
								<li>
									<BsLightningFill className="check-icon" />
									Aptitude test will be of 90 minutes and will Comprise 50
									Multiple Choice/Objective type questions in two sections. Each
									questions carries two marks.
									<ul>
										<li>
											<BsLightningFill className="check-icon" />
											Mathematics: 25 questions
										</li>
										<li>
											<BsLightningFill className="check-icon" />
											General Ability Test: 25 questions
										</li>
									</ul>
								</li>
								<li>
									<BsLightningFill className="check-icon" />
									<b>Negative Marking:</b> One fourth of the marks of each
									question will be deducted for wrong answers..
								</li>
								<li>
									<BsLightningFill className="check-icon" />
									The shortlisted candidates will be called for counseling,
									where they will be interviewed by a panel of experts.
								</li>
								<li>
									<BsLightningFill className="check-icon" />
									The final selection will be based on the candidate's
									performance in the entrance examination and counseling.
								</li>
							</ul>
						</div>

						<div className="ImportantDates" data-aos="fade-up">
							<div className="SubHeading">
								<h4>Important</h4>
							</div>
							<ul>
								<li>
									<BsLightningFill className="check-icon" />
									Fee must be paid in full at the time of admission.
								</li>
								<li>
									<BsLightningFill className="check-icon" />
									75% attendance is necessary for filling of examination from
									according to University rules.
								</li>
								<li>
									<BsLightningFill className="check-icon" />
									Disciplinary action will be taken against those students who
									break college rules
								</li>
								<li>
									<BsLightningFill className="check-icon" />
									All students are directed to see notice board daily without
									fail for information.
								</li>
							</ul>
						</div>

						<div className="HowToApply" data-aos="fade-up">
							<div className="SubHeading">
								<h4>How to Apply</h4>
							</div>
							<ul>
								<li>
									<BsLightningFill className="check-icon" />
									The applicant can applied their form from the{" "}
									<a href="https://jharkhanduniversities.nic.in/home">
										Chancellor Portal
									</a>{" "}
									website.
								</li>
								<li>
									<BsLightningFill className="check-icon" />
									The filled in application form must be submitted along with
									the following documents At the time of counseling and
									Admission:
									<ul>
										<li>
											<BsLightningFill className="check-icon" />
											1. Marks Statement of the qualifying examination/
											appearing certificate.
										</li>
										<li>
											<BsLightningFill className="check-icon" />
											2. Matriculation certificate(as proof of age)
										</li>
										<li>
											<BsLightningFill className="check-icon" />
											3. School/College leaving certificate
										</li>
										<li>
											<BsLightningFill className="check-icon" />
											4. Character certificate
										</li>
										<li>
											<BsLightningFill className="check-icon" />
											5. OBC certificate (SDO Level), EWS/SC/ST certificate (if
											applicable)
										</li>
										<li>
											<BsLightningFill className="check-icon" />
											6. Copy of filled admission form
										</li>
									</ul>
								</li>
								<li>
									<BsLightningFill className="check-icon" />
									The application fee is Rs. 500/- for general SC/ST/PWD
									candidates.
								</li>
							</ul>
						</div>
					</div>

					<div className="SemesterDetailsContainer">
						<div id="Secondary_Heading">
							<h3>Semester</h3>
						</div>
						<div className="Accordion">
							<SemAccordion />
						</div>
					</div>

					<section>
						<Courses />
					</section>
				</div>
			</div>
		</>
	);
}

export default BCA;
