import React from "react";
import Data from "../content.json";
import { FaHandPointRight } from "react-icons/fa";
import { MdMore } from "react-icons/md";
import { Helmet } from "react-helmet";

function NonTeachingMembers() {
  // console.log(teaching-members);

  return (
		<>
			<Helmet>
				<title>Non Teaching Member</title>
			</Helmet>
			<div className="MembersContainer">
				<div id="Heading" data-aos="fade-up">
					<h1>Non Teaching Members</h1>
				</div>

				<div className="Members">
					{Object.entries(Data.nonTeachingMembers).map((nonTeachingData) => {
						console.log(nonTeachingData[1]);

						return (
							<div className="MemberCard" data-aos="fade-up">
								<div className="TopMemberCard">
									<img src={nonTeachingData[1].img} alt="memberImg1" />
									<h2>{nonTeachingData[1].name}</h2>
									<h4>{nonTeachingData[1].role}</h4>
								</div>
								<div className="MiddleMemberCard">
									<table>
										<tr>
											<span className="MiddleMemberCardFirstP">
												<td>
													<FaHandPointRight className="tickIcon" />
												</td>
												<tr>
													<p>{nonTeachingData[1].qualification}</p>
												</tr>
											</span>
										</tr>

										{/* <tr>
                      <p>
                        <td>
                          <FaHandPointRight className="tickIcon" />
                        </td>
                        <td>{nonTeachingData[1].Specialization}</td>
                      </p>
                    </tr> */}
										<tr>
											<span className="MiddleMemberCardFirstP">
												<td>
													<FaHandPointRight className="tickIcon" />
												</td>
												<td>
													<p>{nonTeachingData[1].email}</p>
												</td>
											</span>
										</tr>
									</table>
								</div>
								<div className="BottomMemberCard">
									<a href={nonTeachingData[1].resume}>
										<button className="resumebtn">
											More Details &nbsp;
											<MdMore size={"20px"} />
										</button>
									</a>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
}

export default NonTeachingMembers;
