import React from "react";
import { Helmet } from "react-helmet";

function Auditorium() {
  return (
		<>
			<Helmet>
				<title>Auditorium</title>
			</Helmet>
			<div className="PageContainer">
				<div className="Campus">
					<div className="BannerImg HeaderImg">
						<img
							src="/Images/Campus/Auditorium/auditorium-hero.jpg"
							alt="banenr-img"
						/>
					</div>

					<div id="Heading">
						<h1>Auditorium</h1>
					</div>

					<div className="Desc">
						<div className="DescParagraph">
							<p>
								College auditoriums are typically equipped with a stage, sound
								system, and lighting system. They may also have a projection
								system for displaying images or videos. The size and layout of
								an auditorium will vary depending on the specific needs of the
								college. <br />
								<br />
								The auditorium is an important part of the college experience.
								It provides a space for students to learn, grow, and be
								entertained. It also serves as a central gathering place for the
								college community.
							</p>
						</div>
						<div className="DescImg">
							<img
								src="/Images/Campus/Auditorium/audiotorium-1.jpg"
								alt="img1"
							/>
						</div>
					</div>

					<div className="PhotosSection">
						<div id="Secondary_Heading">
							<h3>Photos</h3>
						</div>

						<div className="PhotoContainer">
							<div className="PhotoRow">
								<div className="Img1">
									<img
										src="/Images/Campus/Auditorium/audiotorium-4.jpg"
										alt="imgdinner"
									/>
								</div>
								<div className="Img2">
									<img
										src="/Images/Campus/Auditorium/audiotorium-3.jpg"
										alt="imgdinner"
									/>
								</div>
							</div>
							<div className="PhotoRow">
								<div className="Img1">
									<img
										src="/Images/Campus/Auditorium/audiotorium-2.jpg"
										alt="imgdinner"
									/>
								</div>
								<div className="Img2">
									<img
										src="/Images/Campus/Auditorium/audiotorium-5.jpg"
										alt="imgdinner"
									/>
								</div>
							</div>
							<div className="PhotoRow">
								<div className="Img3">
									<img
										src="/Images/Campus/Auditorium/audiotorium-2.jpg"
										alt="imgdinner"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Auditorium;
