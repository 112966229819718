import React from "react";
import BCA_MCA from "../Components/BCA_MCA";
import GalleryCarousel from "../Components/GalleryCarousel";
import AdmissonProcessAccordion from "../Components/AdmissonProcessAccordion";
import { Helmet } from "react-helmet";

function AdmissionProcess() {
  return (
		<>
			<Helmet>
				<title>Admission Process</title>
			</Helmet>
			<div className="PageContainer">
				<div className="AdmissionHeader HeaderImg">
					<img
						src="/Images/Banner/admissionProcess_banner.png"
						alt="admission-banner"
					/>
				</div>

				<div id="Heading" data-aos="fade-up">
					<h1>Admission Process</h1>
				</div>
				<div className="AdmissionProcessDesc">
					<p data-aos="fade-up">
						The college admissions process is a complex and competitive one, but
						it is also an important step in preparing for a successful future.
						The process typically begins in the junior year of high school, when
						students begin to research colleges and universities that they are
						interested in attending. Once they have identified a few schools,
						they can start to prepare their applications.
						<br />
						<br />
						The college admissions process can be daunting, but it is important
						to remember that you are not alone. There are many resources
						available to help you navigate the process, including your guidance
						counselor, teachers, and college admissions advisors. With careful
						planning and preparation, you can increase your chances of being
						admitted to the college of your choice.
					</p>
				</div>
				<div className="AdmissionProcessAccordion">
					<AdmissonProcessAccordion />
				</div>
				<div className="Admission_process_Courses">
					<div id="Secondary_Heading" data-aos="fade-up">
						<h3>Courses</h3>
					</div>
					<BCA_MCA />
					<GalleryCarousel />
				</div>
			</div>
		</>
	);
}

export default AdmissionProcess;
