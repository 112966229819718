import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import Loader from "./Loader";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
function ResearchForm() {
  const [loading, setLoading] = useState(false);

  function submitHandler(e) {
    setLoading(true);
    e.preventDefault();
    const captcha_data = e.target.captcha.value;
    if (validateCaptcha(captcha_data) === true) {
      axios
        .post("/api/research/add", {
          name: e.target.name.value,
          email: e.target.email.value,
          phone: e.target.number.value,
          rTopic: e.target.topic.value,
          faculty: e.target.faculty.value,
          passingYear: e.target.passing_year.value,
          guide: e.target.guide.value,
          coGuide: e.target.coguide.value,
          preRegSeminarDate: e.target.preregistration.value,
          address: e.target.address.value,
        })
        .then((res) => {
          setLoading(false);
          alert(res.data.msg);
          if (res.data.msg === "Data sent Successfully") {
            window.location.reload();
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else {
      alert("Captcha Does Not Match");
      setLoading(false);
    }
  }
  const [showDatePicker, setShowDatePicker] = useState(false);
  const showDatePickerHandler = () => {
    setShowDatePicker(true);
  };

  const hideDatePickerHandler = () => {
    setShowDatePicker(false);
  };
  useEffect(() => {
    loadCaptchaEnginge(6);
  }, []);
  return (
    <>
      {loading ? <Loader /> : ""}
      <div className="ResearchContainer">
        <div className="Research">
          <div className="ResearchHeading" data-aos="fade-up">
            <div>
              <h3>Research Registration Form</h3>
              <span className="border-bottom"></span>
            </div>
          </div>
          <div className="ResearchForm" data-aos="fade-up">
            <form onSubmit={submitHandler}>
              <div className="Row" data-aos="fade-up">
                <div className="RowLeft">
                  <input
                    type="text"
                    name="name"
                    placeholder="Name"
                    maxLength={30}
                    required
                  />
                </div>
                <div className="RowRight">
                  <input
                    type="email"
                    name="email"
                    placeholder="E-mail"
                    maxLength={40}
                    required
                  />
                </div>
              </div>
              <div className="Row" data-aos="fade-up">
                <div className="RowLeft">
                  <input
                    type="text"
                    maxLength={10}
                    pattern="\d*"
                    name="number"
                    placeholder="Phone No."
                    required
                  />
                </div>
                <div className="RowRight">
                  <input
                    type="text"
                    name="topic"
                    placeholder="Research Topic"
                    maxLength={30}
                    required
                  />
                </div>
              </div>
              <div className="Row" data-aos="fade-up">
                <div className="RowLeft">
                  <input
                    type="text"
                    name="faculty"
                    placeholder="Faculty"
                    required
                    maxLength={15}
                  />
                </div>
                <div className="RowLeft">
                  <input
                    type="text"
                    name="passing_year"
                    placeholder="Passing Year"
                    maxLength={4}
                    required
                  />
                </div>
                {/* <div className="RowRight"></div> */}
                {/* </div> */}
                {/* <div className="Row" data-aos="fade-up"> */}
                <div className="RowRight">
                  <input
                    type="text"
                    name="guide"
                    placeholder="Guide"
                    required
                    maxLength={30}
                  />
                </div>
                <div className="RowRight">
                  <input
                    type="text"
                    name="coguide"
                    placeholder="Co-Guide (Optional)"
                    maxLength={30}
                  />
                </div>
              </div>

              <div className="Row" data-aos="fade-up">
                <div className="RowLeft">
                  {showDatePicker ? (
                    <input
                      type="date"
                      name="preregistration"
                      placeholder="Pre-registration Seminar Date"
                      onBlur={hideDatePickerHandler}
                      required
                    />
                  ) : (
                    <input
                      type="text"
                      name="preregistration"
                      placeholder="Pre-registration Seminar Date"
                      onFocus={showDatePickerHandler}
                      required
                    />
                  )}
                </div>

                <div className="RowRight">
                  <input
                    type="text"
                    name="address"
                    placeholder="Address"
                    required
                    maxLength={100}
                  />
                </div>
              </div>
              <div
                className="Row"
                data-aos="fade-up"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                }}
              >
                <LoadCanvasTemplate style={{ display: "flex" }} />
                <input
                  type="text"
                  name="captcha"
                  placeholder="Enter Captcha Here"
                  className="capthca_box"
                  required
                />
              </div>
              <input type="submit" value="Submit" data-aos="fade-up" />
            </form>
          </div>
        </div>
      </div>
      <br />
    </>
  );
}

export default ResearchForm;
