import React from "react";
import { Helmet } from "react-helmet";

function Library() {
  return (
		<>
			<Helmet>
				<title>MCA-Library</title>
			</Helmet>
			<div className="PageContainer">
				<div className="Campus">
					<div className="BannerImg HeaderImg">
						<img src="/Images/Campus/Dept/lib.jpg" alt="banenr-img" />
					</div>

					<div id="Heading">
						<h1>Library</h1>
					</div>

					<div className="Desc">
						<div className="DescParagraph">
							<p>
								A college library is a place where students, faculty, and staff
								can go to access a variety of resources, including books,
								journals, magazines, newspapers, electronic databases, and
								multimedia materials. It is also a place where people can go to
								study, collaborate, and conduct research.
								<br />
								<br />
								College libraries are an important resource for students,
								faculty, and staff. They provide access to a wide range of
								resources and services that can help people with their academic,
								professional, and personal goals.
							</p>
						</div>
						<div className="DescImg">
							<img src="/Images/Campus/Dept/library-1.jpg" alt="img1" />
						</div>
					</div>

					<div className="PhotosSection">
						<div id="Secondary_Heading">
							<h3>Photos</h3>
						</div>

						<div className="PhotoContainer">
							<div className="PhotoRow">
								<div className="Img1">
									<img
										src="/Images/Campus/Dept/library-6.jpg"
										alt="imgdinner"
									/>
								</div>
								<div className="Img2">
									<img
										src="/Images/Campus/Dept/library-3.jpg"
										alt="imgdinner"
									/>
								</div>
							</div>
							<div className="PhotoRow">
								<div className="Img1">
									<img
										src="/Images/Campus/Dept/library-4.jpg"
										alt="imgdinner"
									/>
								</div>
								<div className="Img2">
									<img
										src="/Images/Campus/Dept/library-5.jpg"
										alt="imgdinner"
									/>
								</div>
							</div>
							<div className="PhotoRow">
								<div className="Img3">
									<img
										src="/Images/Campus/Dept/library-2.jpg"
										alt="imgdinner"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Library;
