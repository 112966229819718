import React from 'react';
function HeroSection() {

  
  return (
   <>
   <section>
   <div className="HeroSection" >
    {/* <img src="/Images/vbuImg.png" alt='heroImg'/> */}
    <img src="/Images/Campus/Dept/dept-1.jpg" alt='heroImg'/>
   </div>
   </section>
   </>
  )
}



export default HeroSection