import React from "react";

function MessageToStudent() {
  return (
    <>
      <div className="MessageToStudentContainer" data-aos="fade-up">
        <div id="Heading">
          <h1>Message To Student</h1>
        </div>

        <div className="MessageToStudent" data-aos="fade-up">
          <div className="MessageLeft">
            <div className="Message">
              <p>
                {/* The ever growing and synergetic blend of technologies and innovation is fueling dynamism in global arena. University Department of Computer Applications has a legacy of professional excellence associated with it.  */}
                {/* The Department lays the foundation of conceptual and analytical reasoning, giving the student as insight into the dynamics of corporate world. */}
                I am pleased to welcome you to University Department
                Applications of Vinoba Bhave University, Hazaribag, Jharkhand
                for the three year "Master of Computer Applications" approved by
                AICTE and newly started one year PGDCA and two years M.Sc(I.T).
                <br />
                <br />
                Our aim is to build future leader for IT industry worldwide by
                imparting world class IT education for building leading edge and
                innovative IT applications.
                {/* IT has become a critical tool for economic, business and social development and will play a pivotal and catalytic role in nation's progress. The fundamental principal on which we lean on are to use IT to solve business problems. */}
                The department is progressing by leaps and bounds under the able
                and dynamic leadership of our Vice Chancellor.
              </p>
            </div>
            <div className="Signature">
              <p>Dr. Ashish Kr. Saha</p>
              <p>Director</p>
            </div>
          </div>
          <div className="MessageRight">
            <img src="Images/director.jpg" alt="director-img" />
          </div>
        </div>
      </div>
    </>
  );
}

export default MessageToStudent;
