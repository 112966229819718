import React from "react";
import { Helmet } from "react-helmet";

function Park() {
  return (
		<>
			<Helmet>
				<title>VBU-Park</title>
			</Helmet>
			<div className="PageContainer">
				<div className="Campus">
					<div className="BannerImg HeaderImg">
						<img src="/Images/Campus/Park/park1.jpg" alt="banenr-img" />
					</div>

					<div id="Heading">
						<h1>Park</h1>
					</div>

					<div className="Desc">
						<div className="DescParagraph">
							<p>
								Parks on university college campuses are an important part of
								the campus community. They provide a place for students,
								faculty, and staff to relax, socialize, and learn.
								<br />
								<br />A park on a university college campus is a green space
								that is typically located in a central location on campus. It is
								open to all students, faculty, and staff, and can be used for a
								variety of purposes
							</p>
						</div>
						<div className="DescImg">
							<img src="Images/Campus/Park/park.jpg" alt="img1" />
						</div>
					</div>

					<div className="PhotosSection">
						<div id="Secondary_Heading">
							<h3>Photos</h3>
						</div>

						<div className="PhotoContainer">
							<div className="PhotoRow">
								<div className="Img1">
									<img src="Images/Campus/Park/park-1.jpg" alt="imgdinner" />
								</div>
								<div className="Img2">
									<img src="Images/Campus/Park/park-2.jpg" alt="imgdinner" />
								</div>
							</div>
							<div className="PhotoRow">
								<div className="Img1">
									<img src="Images/Campus/Park/park-7.jpg" alt="imgdinner" />
								</div>
								<div className="Img2">
									<img src="Images/Campus/Park/park-4.jpg" alt="imgdinner" />
								</div>
							</div>
							<div className="PhotoRow">
								<div className="Img3">
									<img src="Images/Campus/Park/park-5.jpg" alt="imgdinner" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Park;
