import React, { useEffect, useState } from "react";
import { FiUpload } from "react-icons/fi";
import axios from "axios";
import Loader from "../Components/Loader";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";

function AlumniForm() {
  const [loading, setLoading] = useState(false);
  const [file, setfile] = useState();
  const [img, setImg] = useState();
  function imageHandler(e) {
    console.log(e.target.files);
    setfile(URL.createObjectURL(e.target.files[0])); // for preview
    setImg(e.target.files[0]);
  }
  const [showDatePicker, setShowDatePicker] = useState(false);
  const showDatePickerHandler = () => {
    setShowDatePicker(true);
  };
  const hideDatePickerHandler = () => {
    setShowDatePicker(false);
  };

  const submitHandler = (e) => {
    setLoading(true);
    e.preventDefault();
    const captcha_data = e.target.captcha.value;
    let formData = new FormData();
    formData.append("name", e.target.name.value);
    formData.append("email", e.target.email.value);
    formData.append("dob", e.target.dob.value);
    formData.append("mobile", e.target.number.value);
    formData.append("company", e.target.company.value);
    formData.append("designation", e.target.designation.value);
    formData.append("regno", e.target.registration.value);
    formData.append("rollno", e.target.roll.value);
    formData.append("course", e.target.course.value);
    formData.append("passingYear", e.target.passingYear.value);
    formData.append("linkedin", e.target.url.value);
    formData.append("image", img);
    if (validateCaptcha(captcha_data) === true) {
      axios
        .post("/api/alumini/add", formData)
        .then((res) => {
          setLoading(false);
          // console.log(res);
          alert(res.data.msg);
          if (res.data.msg === "Data sent Successfully") {
            window.location.reload();
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else {
      alert("Captcha Does Not Match");
      setLoading(false);
    }
  };

  useEffect(() => {
    loadCaptchaEnginge(6);
  }, []);
  return (
    <>
      {loading ? <Loader /> : ""}
      <div className="AlumniContainer">
        <div className="Alumni">
          <div className="AlumniHeading" data-aos="fade-up">
            <div>
              <h3 style={{ textAlign: "center" }}>Alumni Registration Form</h3>
              <span className="border-bottom"></span>
            </div>
          </div>

          <div className="AlumniForm">
            <form onSubmit={submitHandler} enctype="multipart/form-data">
              <div className="TopRow" data-aos="fade-up">
                <div className="UploadImg">
                  <input
                    type="file"
                    id="file"
                    name="image"
                    accept="image/*"
                    onChange={imageHandler}
                    required
                  />
                  {/* <img src={file}/> */}
                  <label htmlFor="file" className="filebox">
                    {/* <div> */}
                    <span>
                      <FiUpload />
                    </span>
                    <span style={{ textAlign: "center" }}>Upload Image</span>
                    {/* </div> */}
                    <img id="file-img" src={file} />
                  </label>
                </div>
                <div className="nameEmail">
                  <input
                    type="text"
                    name="name"
                    maxLength={30}
                    placeholder="Name"
                    required
                  />
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    maxLength={40}
                    required
                  />
                </div>
              </div>
              <div className="Row" data-aos="fade-up">
                <div className="RowLeft">
                  {showDatePicker ? (
                    <input
                      type="date"
                      name="dob"
                      onBlur={hideDatePickerHandler}
                      required
                    />
                  ) : (
                    <input
                      type="text"
                      name="dob"
                      placeholder="D.O.B"
                      onFocus={showDatePickerHandler}
                      required
                    />
                  )}
                </div>

                <div className="RowRight">
                  <input
                    type="text"
                    pattern="\d{10}"
                    maxLength={10}
                    name="number"
                    placeholder="Mobile No."
                    required
                  />
                </div>
              </div>
              <div className="Row" data-aos="fade-up">
                <div className="RowLeft">
                  <input
                    type="text"
                    name="company"
                    placeholder="Company"
                    maxLength={50}
                    required
                  />
                </div>
                <div className="RowRight">
                  <input
                    type="text"
                    name="designation"
                    placeholder="Designation"
                    maxLength={30}
                    required
                  />
                </div>
              </div>
              <div className="Row" data-aos="fade-up">
                <div className="RowLeft">
                  <input
                    type="text"
                    name="registration"
                    placeholder="Univ. Registration Number"
                    required
                    maxLength={20}
                  />
                </div>
                <div className="RowRight">
                  <input
                    type="text"
                    pattern="\d*"
                    name="roll"
                    placeholder="Univ. Roll Number"
                    maxLength={20}
                    required
                  />
                </div>
              </div>
              <div className="Row" data-aos="fade-up">
                <div className="RowLeft">
                  <select name="course" required>
                    <option value="">Choose Course</option>
                    <option value="BCA">BCA</option>
                    <option value="BCA">MCA</option>
                    <option value="BCA">Phd</option>
                  </select>
                  <select name="passingYear" required>
                    <option value="">Passing Year</option>
                    <option value="2012">2012</option>
                    <option value="2013">2013</option>
                    <option value="2014">2014</option>
                    <option value="2015">2015</option>
                    <option value="2016">2016</option>
                    <option value="2017">2017</option>
                    <option value="2018">2018</option>
                    <option value="2019">2019</option>
                    <option value="2020">2020</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2026">2026</option>
                    <option value="2027">2027</option>
                  </select>
                </div>
                <div className="RowRight">
                  <input
                    type="text"
                    name="url"
                    placeholder="LinkedIn Id"
                    required
                  />
                </div>
              </div>

              <div
                className="Row"
                data-aos="fade-up"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                }}
              >
                <LoadCanvasTemplate style={{ display: "flex" }} />
                <input
                  type="text"
                  name="captcha"
                  placeholder="Enter Captcha Here"
                  className="capthca_box"
                  required
                />
              </div>

              <input type="submit" value="Submit" name="submit" />
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default AlumniForm;
