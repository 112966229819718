import React from "react";
import { Link } from "react-router-dom";
import GalleryData from "../Gallery.json";
import { Helmet } from "react-helmet";

function Gallery() {
  return (
		<>
			<Helmet>
				<title>Gallery</title>
			</Helmet>
			<div className="PageContainer">
				<div className="GalleryContainer">
					<div className="GalleryBanner HeaderImg">
						<img src="/Images/Banner/Gallery_banner.png" alt="Gallery-banner" />
					</div>

					<div className="GalleryRow" data-aos="fade-up">
						<div className="GalleryTitle" data-aos="fade-up">
							<h5 id="Secondary_Heading" data-aos="fade-up">
								Campus Life
							</h5>
							{/* <h5 className="viewMore" data-aos="fade-up"><Link to="">View More</Link></h5> */}
						</div>

						<div className="GalleryCardContainer" data-aos="fade-up">
							{Object.entries(GalleryData.Campus_Life).map((campus) => {
								return (
									<div className="GalleryCard" data-aos="fade-up">
										<img src={campus[1].img} alt="img" />
										<p>{campus[1].desc}</p>
									</div>
								);
							})}
						</div>
					</div>

					<div className="GalleryRow" data-aos="fade-up">
						<div className="GalleryTitle" data-aos="fade-up">
							<h5 id="Secondary_Heading">Laboratory</h5>
						</div>

						<div className="GalleryCardContainer" data-aos="fade-up">
							{Object.entries(GalleryData.Laboratory).map((lab) => {
								return (
									<div className="GalleryCard" data-aos="fade-up">
										<img src={lab[1].img} alt="img" />
										<p>{lab[1].desc}</p>
									</div>
								);
							})}
						</div>
					</div>

					<div className="GalleryRow" data-aos="fade-up">
						<div className="GalleryTitle" data-aos="fade-up">
							<h5 id="Secondary_Heading" data-aos="fade-up">
								Class Room
							</h5>
						</div>

						<div className="GalleryCardContainer" data-aos="fade-up">
							{Object.entries(GalleryData.Class_Room).map((classroom) => {
								return (
									<div className="GalleryCard" data-aos="fade-up">
										<img src={classroom[1].img} alt="img" />
										<p>{classroom[1].desc}</p>
									</div>
								);
							})}
						</div>
					</div>

					<div className="GalleryRow" data-aos="fade-up">
						<div className="GalleryTitle" data-aos="fade-up">
							<h5 id="Secondary_Heading" data-aos="fade-up">
								Library
							</h5>
						</div>

						<div className="GalleryCardContainer" data-aos="fade-up">
							{Object.entries(GalleryData.Library).map((library) => {
								return (
									<div className="GalleryCard" data-aos="fade-up">
										<img src={library[1].img} alt="img" />
										<p>{library[1].desc}</p>
									</div>
								);
							})}
						</div>
					</div>

					<div className="GalleryRow" data-aos="fade-up">
						<div className="GalleryTitle" data-aos="fade-up">
							<h5 id="Secondary_Heading" data-aos="fade-up">
								Events
							</h5>
						</div>

						<div className="GalleryCardContainer" data-aos="fade-up">
							{Object.entries(GalleryData.Events).map((events) => {
								return (
									<div className="GalleryCard" data-aos="fade-up">
										<img src={events[1].img} alt="img" />
										<p>{events[1].desc}</p>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Gallery;
