import React from "react";
import { BsLightningFill } from "react-icons/bs";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
function AdmissonProcessAccordion() {
  return (
    <>
      <Accordion preExpanded={["a"]}>
        <AccordionItem uuid="a">
          <AccordionItemHeading>
            <AccordionItemButton>Step 1: Eligibility</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              <ul>
                To be eligible for admission to the Vinoba Bhave University BCA
                program, you must meet the following requirements:
                <br />
                <br />
                <li>
                  Any students who have passed Intermediate Science, Arts &
                  Commerce with mathematics/Business Mathematics/Computer
                  Science/Information Practices(I.P.) as one of the subjects.
                  And having minimum 45% marks are eligible to take admission in
                  this course.
                </li>
              </ul>
              <br />

              <ul>
                To be eligible for admission to the Vinoba Bhave University MCA
                program, you must meet the following requirements:
                <br />
                <br />
                <li>
                  Any students who Pass or Appearing in BCA/ B.Sc (IT)/Any
                  Graduate with aggregate 50% and having Mathematics as one of
                  the subjects at 10+2 level or at graduation.
                </li>
              </ul>
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem uuid="b">
          <AccordionItemHeading>
            <AccordionItemButton>Step 2: Application</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              The applicant can applied their form from the{" "}
              <a href="https://jharkhanduniversities.nic.in/home">
                Chancellor Portal
              </a>{" "}
              website.
              <br />
              The application fee is Rs. 500/- for general SC/ST/PWD candidates.
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem uuid="c">
          <AccordionItemHeading>
            <AccordionItemButton>
              Step 3: Entrance Examination
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              Students for the admission in BCA/MCA will be selected through
              Entrance Aptitude Test followed by counseling conducted by the
              Department.
              <br /><br/>
              Aptitude test will be of 90 minutes and will Comprise 50 Multiple
              Choice/Objective type questions in two sections. Each questions
              carries two marks and for each wrong answer a candidate will earn
              0.25 negative marks.
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem uuid="d">
          <AccordionItemHeading>
            <AccordionItemButton>Step 4: Counseling</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              The shortlisted candidates will be called for counseling, where
              they will be interviewed by a panel of experts.
              <br />
              The final selection will be based on the candidate's performance
              in the entrance examination and counseling.
              <br />
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem uuid="e">
          <AccordionItemHeading>
            <AccordionItemButton>Step 5: Admission</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              The filled in application form must be submitted along with the
              following documents At the time of counseling and Admission:
              <ul>
                <li>
                  <BsLightningFill className="check-icon" />
                  1. Xerox Copy and original of Marks Statement of 10th ,12th
                  and graduation
                </li>
                <li>
                  <BsLightningFill className="check-icon" />
                  2. Xerox Copy and original of Category (SC/ST/OBC(SDO
                  LEVEL)/EWS) Certificate (if Applicable).
                </li>
                <li>
                  <BsLightningFill className="check-icon" />
                  3. Xerox Copy of AADHAR.
                </li>
                <li>
                  <BsLightningFill className="check-icon" />
                  4. Original Migration and Transfer Certificate (TC)
                  /Department Leaving Certificate (DLC)
                </li>
                <li>
                  <BsLightningFill className="check-icon" />
                  5. Two Colored Passport Size Photographs
                </li>
                <li>
                  <BsLightningFill className="check-icon" />
                  6. Copy of filled admission form
                </li>
              </ul>
            </p>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
}

export default AdmissonProcessAccordion;
