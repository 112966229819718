import React from "react";
import { Helmet } from "react-helmet";

function Canteen() {
  return (
		<>
			<Helmet>
				<title>VBU-Canteen</title>
			</Helmet>
			<div className="PageContainer">
				<div className="Campus">
					<div className="BannerImg HeaderImg">
						<img src="/Images/Campus/Canteen/frontpic.png" alt="banenr-img" />
					</div>

					<div id="Heading">
						<h1>Canteen</h1>
					</div>

					<div className="Desc">
						<div className="DescParagraph">
							<p>
								A canteen on a university campus is a place where students,
								faculty, and staff can go to eat and socialize. It is typically
								located in a central location on campus and is open during the
								day and sometimes in the evening.Canteens on university campuses
								can vary in size and offerings. Some canteens may be small and
								only offer a limited selection of food, while others may be
								large and have a variety of food options, including hot meals,
								cold snacks, and drinks.
								<br />
								<br />
								Canteens on university campuses are a popular spot for students
								to eat lunch and dinner. They are also a great place for
								students to meet up with friends and socialize.
							</p>
						</div>
						<div className="DescImg">
							<img src="/Images/Campus/Canteen/drinks.png" alt="img1" />
						</div>
					</div>

					<div className="PhotosSection">
						<div id="Secondary_Heading">
							<h3>Photos</h3>
						</div>

						<div className="PhotoContainer">
							<div className="PhotoRow">
								<div className="Img1">
									<img
										src="/Images/Campus/Canteen/dinner.png"
										alt="imgdinner"
									/>
								</div>
								<div className="Img2">
									<img
										src="/Images/Campus/Canteen/drinks.png"
										alt="imgdinner"
									/>
								</div>
							</div>
							<div className="PhotoRow">
								<div className="Img1">
									<img src="/Images/Campus/Canteen/lunch.png" alt="imgdinner" />
								</div>
								<div className="Img2">
									<img
										src="/Images/Campus/Canteen/breakfast.png"
										alt="imgdinner"
									/>
								</div>
							</div>
							<div className="PhotoRow">
								<div className="Img3">
									<img
										src="/Images/Campus/Canteen/snacks.png"
										alt="imgdinner"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Canteen;
