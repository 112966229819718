import React, { useEffect, useState } from "react";
import axios from "axios";
function ResearchList() {
	const [data, setData] = useState([]);
	useEffect(() => {
		fetchResearchData();
	}, []);

	function fetchResearchData() {
		axios
			.get("/api/research/listResearchClient")
			.then((res) => {
				
				setData(res.data.Data);
			})
			.catch((err) => {
				console.log(err);
			});
	}

	return (
		<>
			<div className="PageContainer">
				{/* Alumni Table  */}
				<div className="AlumniTableContainer">
					<div className="AlumniHeading">
						<div>
							<h3>Roll of Honours(Ph.D)</h3>
							<span className="border-bottom"></span>
						</div>
					</div>

					<div className="AlumniTable">
						<table>
							<thead>
								<tr>
									<th>S.No</th>
									<th>Name of Scholar</th>
									<th>Name of Supervisor</th>
									<th>Topic</th>
									<th>Faculty</th>
									<th>Year</th>
								</tr>
							</thead>
							<tbody>
								{data === undefined ||
									data.map((item, index) => {
										return (
											<tr key={index}>
												<td>{index + 1}</td>
												<td>{item.name}</td>
												<td>{item.guide}</td>
												<td>{item.rTopic}</td>
												<td>{item.faculty}</td>
												<td>{item.passingYear}</td>
											</tr>
										);
									})}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	);
}

export default ResearchList;
