import React from "react";
import { Helmet } from "react-helmet";

function Ground() {
  return (
		<>
			<Helmet>
				<title>VBU-Ground</title>
			</Helmet>
			<div className="PageContainer">
				<div className="Campus">
					<div className="BannerImg HeaderImg">
						<img src="/Images/Campus/Campus/ground-hero.jpg" alt="banenr-img" />
					</div>

					<div id="Heading">
						<h1>Play Ground</h1>
					</div>

					<div className="Desc">
						<div className="DescParagraph">
							<p>
								Sure, here is a short note on a playground of a college campus:
								A playground on a college campus is a space where students can
								go to play sports, relax, and socialize. It is typically located
								in a central location on campus and is open to all students.
								Playgrounds on college campuses can vary in size and amenities.
								Some playgrounds may have only a few basic pieces of equipment,
								such as swings, slides, and a basketball hoop. Others may have
								more extensive facilities, such as a football field, a tennis
								court, and a track.
								<br />
								<br />
								Playgrounds on college campuses are a great way for students to
								get exercise, relieve stress, and make friends. They can also be
								a place for students to relax and enjoy the outdoors.
							</p>
						</div>
						<div className="DescImg">
							<img src="/Images/Campus/Campus/ground-1.jpg" alt="img1" />
						</div>
					</div>

					<div className="PhotosSection">
						<div id="Secondary_Heading">
							<h3>Photos</h3>
						</div>

						<div className="PhotoContainer">
							<div className="PhotoRow">
								<div className="Img1">
									<img
										src="/Images/Campus/Campus/ground-4.jpg"
										alt="imgdinner"
									/>
								</div>
								<div className="Img2">
									<img
										src="/Images/Campus/Campus/ground-2.jpg"
										alt="imgdinner"
									/>
								</div>
							</div>
							<div className="PhotoRow">
								<div className="Img1">
									<img
										src="/Images/Campus/Campus/ground-3.jpg"
										alt="imgdinner"
									/>
								</div>
								<div className="Img2">
									<img src="/Images/Campus/Campus/ground.jpg" alt="imgdinner" />
								</div>
							</div>
							<div className="PhotoRow">
								<div className="Img3">
									<img src="/Images/Campus/Campus/ground.jpg" alt="imgdinner" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Ground;
