import React, { useState } from "react";
import CoursesData from "../content.json";
import { BsLightningFill } from "react-icons/bs";
import { Helmet } from "react-helmet";


import Courses from "../Components/Courses"
import MCA_SemAccordion from "../Components/MCA_Sem_Accordion";
function MCA() {

  // document.getElementsByClassName("CourseContainer").style.marginTop="0";
  const [MCAData, SetMCAData] = useState(CoursesData.Courses[1]);
  return (
		<>
			<Helmet>
				<title>MCA</title>
			</Helmet>
			<div className="PageContainer">
				<div className="">
					<div className="CourseBanner HeaderImg">
						<img src={MCAData.course_banner} alt="MCA-banner" />
					</div>

					<div id="Heading" className="CourseTitle" data-aos="fade-up">
						<h1>{MCAData.course_fullname}</h1>
					</div>
					<br />
					<p className="CourseDesc" data-aos="fade-up">
						{MCAData.course_desc}
					</p>
					<br />

					<div className="CourseDetailsContainer">
						<div id="Secondary_Heading" data-aos="fade-up">
							<h3>Course Detail</h3>
						</div>
						<div className="CourseTable" data-aos="fade-up">
							<table>
								<tr data-aos="fade-up">
									<td>Course -</td>
									<td>{MCAData.course_fullname}</td>
								</tr>
								<tr data-aos="fade-up">
									<td>Certificate -</td>
									<td>{MCAData.course_certification}</td>
								</tr>

								<tr data-aos="fade-up">
									<td>Duration -</td>
									<td>{MCAData.course_duration}</td>
								</tr>
								<tr data-aos="fade-up">
									<td>No of Semester -</td>
									<td>{MCAData.course_sem}</td>
								</tr>
								<tr data-aos="fade-up">
									<td>Seats -</td>
									<td>{MCAData.course_seat}</td>
								</tr>
								<tr data-aos="fade-up">
									<td>Eligibility -</td>
									<td>{MCAData.course_eligibility}</td>
								</tr>
								<tr data-aos="fade-up">
									<td>Fees -</td>
									<td>{MCAData.course_fee_per_sem}</td>
								</tr>
								<tr data-aos="fade-up">
									<td>University -</td>
									<td>{MCAData.university}</td>
								</tr>
							</table>
						</div>
					</div>

					<div className="CourseDetailsProcess">
						<div className="ElegibilityCriteria" data-aos="fade-up">
							<div className="SubHeading">
								<h4>Elegibility Criteria</h4>
							</div>
							<ul>
								{/* <li>
              <BsLightningFill className="check-icon" />
              Candidate must have completed graduation in BCA / Bachelor Degree in Computer Science Engineering or equivalent Degree from a recognized university.
            </li> */}
								<li>
									<BsLightningFill className="check-icon" />
									Pass or Appearing in BCA/ B.Sc (IT)/Any Graduate with
									aggregate 50% and having Mathematics as one of the subjects at
									10+2 level or at graduation.
								</li>
							</ul>
						</div>

						<div className="SelectionProcedure" data-aos="fade-up">
							<div className="SubHeading">
								<h4>Selection Procedure </h4>
							</div>
							<ul>
								<li>
									<BsLightningFill className="check-icon" />
									Students for the admission in MCA will be selected through
									Entrance Aptitude Test followed by counseling conducted by the
									Department.
								</li>
								<li>
									<BsLightningFill className="check-icon" />
									The entrance examination will be of 90 minutes and will
									comprise 50 Multiple Choice/Objective type questions in three
									sections:
									<ul>
										<li>
											<BsLightningFill className="check-icon" />
											Mathematics: 20 questions
										</li>
										<li>
											<BsLightningFill className="check-icon" />
											Analytical ability & Logical Reasoning: 10 questions
										</li>
										<li>
											<BsLightningFill className="check-icon" />
											Computer Awareness: 20 questions
										</li>
									</ul>
								</li>
								<li>
									<BsLightningFill className="check-icon" />
									For each correct answer a candidate will earn 2 marks and for
									each wrong answer a candidate will earn 0.25 negative marks.
								</li>
								<li>
									<BsLightningFill className="check-icon" />
									The shortlisted candidates will be called for counseling,
									where they will be interviewed by a panel of experts.
								</li>
								<li>
									<BsLightningFill className="check-icon" />
									The final selection will be based on the candidate's
									performance in the entrance examination and counseling.
								</li>
							</ul>
						</div>

						<div className="ImportantDates" data-aos="fade-up">
							<div className="SubHeading">
								<h4>Important</h4>
							</div>
							<ul>
								<li>
									<BsLightningFill className="check-icon" />
									Fee must be paid in full at the time of admission.
								</li>
								<li>
									<BsLightningFill className="check-icon" />
									75% attendance is necessary for filling of examination from
									according to University rules.
								</li>
								<li>
									<BsLightningFill className="check-icon" />
									Disciplinary action will be taken against those students who
									break college rules
								</li>
								<li>
									<BsLightningFill className="check-icon" />
									All students are directed to see notice board daily without
									fail for information.
								</li>
							</ul>
						</div>

						<div className="HowToApply" data-aos="fade-up">
							<div className="SubHeading">
								<h4>How to Apply</h4>
							</div>
							<ul>
								<li>
									<BsLightningFill className="check-icon" />
									The applicant can applied their form from the{" "}
									<a href="https://jharkhanduniversities.nic.in/home">
										Chancellor Portal
									</a>{" "}
									website.
								</li>
								<li>
									<BsLightningFill className="check-icon" />
									The filled in application form must be submitted along with
									the following documents At the time of counseling and
									Admission:
									<ul>
										<li>
											<BsLightningFill className="check-icon" />
											1. Xerox Copy and original of Marks Statement of 10th
											,12th and graduation
										</li>
										<li>
											<BsLightningFill className="check-icon" />
											2. Xerox Copy and original of Category (SC/ST/OBC(SDO
											LEVEL)/EWS) Certificate (if Applicable).
										</li>
										<li>
											<BsLightningFill className="check-icon" />
											3. Xerox Copy of AADHAR.
										</li>
										<li>
											<BsLightningFill className="check-icon" />
											4. Original Migration and Transfer Certificate (TC)
											/Department Leaving Certificate (DLC)
										</li>
										<li>
											<BsLightningFill className="check-icon" />
											5. Two Colored Passport Size Photographs
										</li>
										<li>
											<BsLightningFill className="check-icon" />
											6. Copy of filled admission form
										</li>
									</ul>
								</li>
								<li>
									<BsLightningFill className="check-icon" />
									The application fee is Rs. 500/- for general SC/ST/PWD
									candidates.
								</li>
							</ul>
						</div>
					</div>

					<div className="SemesterDetailsContainer" data-aos="fade-up">
						<div id="Secondary_Heading">
							<h3>Semester</h3>
						</div>
						<div className="Accordion">
							<MCA_SemAccordion />
						</div>
					</div>

					<section>
						<Courses />
					</section>
				</div>
			</div>
		</>
	);
}

export default MCA;
