import React from 'react'
import AlumniForm from '../Components/AlumniForm'
import { Helmet } from "react-helmet";

function Alumni() {
  return (
		<>
			<Helmet>
				<title>Alumni Form</title>
			</Helmet>
			<div className="PageContainer">
				<div className="AlumniHeader HeaderImg">
					<img src="/Images/Banner/alumni.jpg" alt="banner" />
				</div>
				<AlumniForm />
			</div>
		</>
	);
}

export default Alumni