import React from 'react'
import {DotLoader  } from "react-spinners"

function Loader() {
  return (
   <>
      <section id="loader">
      <DotLoader   color="#FF0000" className='loader' />
       {/* <ScaleLoader color="#183497" /> */}
       </section>

   </>
  )
}

export default Loader