import React from "react";
import { Helmet } from "react-helmet";

function Gym() {
  return (
		<>
			<Helmet>
				<title>VBU-Gym</title>
			</Helmet>
			<div className="PageContainer">
				<div className="Campus">
					<div className="BannerImg HeaderImg">
						<img src="/Images/Campus/Canteen/frontpic.png" alt="banenr-img" />
					</div>

					<div id="Heading">
						<h1>GYM</h1>
					</div>

					<div className="Desc">
						<div className="DescParagraph">
							<p>
								A gym on a university is a place where students can go to work
								out, exercise, and stay healthy. It is typically located on
								campus and is open to all students. Gyms on university campuses
								can vary in size and amenities. Some gyms may have only a few
								basic pieces of equipment, such as treadmills, ellipticals, and
								weight machines. Others may have more extensive facilities, such
								as swimming pools, basketball courts, and racquetball courts.
								<br />
								<br />
								Gyms on university campuses are a great way for students to stay
								active and healthy. They can also be a place for students to
								relieve stress and de-stress from the pressures of school.
							</p>
						</div>
						<div className="DescImg">
							<img src="/Images/Campus/Canteen/drinks.png" alt="img1" />
						</div>
					</div>

					<div className="PhotosSection">
						<div id="Secondary_Heading">
							<h3>Photos</h3>
						</div>

						<div className="PhotoContainer">
							<div className="PhotoRow">
								<div className="Img1">
									<img
										src="/Images/Campus/Canteen/dinner.png"
										alt="imgdinner"
									/>
								</div>
								<div className="Img2">
									<img
										src="/Images/Campus/Canteen/drinks.png"
										alt="imgdinner"
									/>
								</div>
							</div>
							<div className="PhotoRow">
								<div className="Img1">
									<img src="/Images/Campus/Canteen/lunch.png" alt="imgdinner" />
								</div>
								<div className="Img2">
									<img
										src="/Images/Campus/Canteen/breakfast.png"
										alt="imgdinner"
									/>
								</div>
							</div>
							<div className="PhotoRow">
								<div className="Img3">
									<img
										src="/Images/Campus/Canteen/snacks.png"
										alt="imgdinner"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Gym;
