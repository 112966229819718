import React, { useState, useEffect } from "react";
import { MdLocationOn, MdCall, MdEmail } from "react-icons/md";
import axios from "axios";
import Loader from "../Components/Loader";
import { Helmet } from "react-helmet";
import {
	loadCaptchaEnginge,
	LoadCanvasTemplate,
	validateCaptcha,
} from "react-simple-captcha";
function ContactUs() {
	const [loading, setLoading] = useState(false);

	const contactHandler = (e) => {
		e.preventDefault();
		setLoading(true);
		const captcha_data = e.target.captcha.value;

		if (validateCaptcha(captcha_data) === true) {
			axios
				.post("/api/enquiry/add", {
					name: e.target.name.value,
					email: e.target.email.value,
					phone: e.target.number.value,
					course: e.target.course.value,
					description: e.target.msg.value,
				})
				.then((res) => {
					alert(res.data.msg);
					if (res.data.msg === "Enquiry Sent Successfully") {
						window.location.reload();
					}
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			alert("Captcha Does Not Match");
			setLoading(false);
		}
	};

	useEffect(() => {
		loadCaptchaEnginge(6);
	}, []);
	return (
		<>
			<Helmet>
				<title>Contact Us</title>
			</Helmet>
			{loading ? <Loader /> : ""}

			<div className="PageContainer">
				<div className="ContactHeader HeaderImg">
					<img src="/Images/Banner/contactus_banner.jpg" alt="Contact-banner" />
				</div>
				<div className="ContactMiddle">
					<div className="ContactMiddleLeft" data-aos="fade-left">
						<div className="ContactUsTitle">
							<h3>Contact with Department</h3>
							<br />
						</div>
						<div className="ContactUsDesc">
							<div className="DescRow">
								<tr>
									<td>
										<MdLocationOn className="contact-icon" />
									</td>
									<td>
										<p>
											Department of computer application, VBU, Hazaribagh
											(Jharkhand, 825201)
										</p>
									</td>
								</tr>
							</div>
							{/* <div className="DescRow">
								<tr>
									<td>
										<MdCall className="contact-icon" />
									</td>
									<td>
										<p>7667479088</p>
									</td>
								</tr>
							</div> */}
							<div className="DescRow">
								<tr>
									<td>
										<MdEmail className="contact-icon" />
									</td>
									<td>
										<p>vbumca@gmail.com</p>
									</td>
								</tr>
							</div>
						</div>
					</div>
					<div className="ContactMiddleRight" data-aos="fade-right">
						<div className="ContactUsTitle">
							<h3>Contact with University</h3>
							<br />
						</div>
						<div className="ContactUsDesc">
							<div className="DescRow">
								<tr>
									<td>
										<MdLocationOn className="contact-icon" />
									</td>
									<td>
										<p>
											Department of computer application, VBU, Hazaribagh
											(Jharkhand, 825301)
										</p>
									</td>
								</tr>
							</div>
							<div className="DescRow">
								<tr>
									<td>
										<MdCall className="contact-icon" />
									</td>
									<td>
										<p>7520381245, 8252642652</p>
									</td>
								</tr>
							</div>
							<div className="DescRow">
								<tr>
									<td>
										<MdEmail className="contact-icon" />
									</td>
									<td>
										<ul>
											<li>ce@vbu.ac.in</li>
											<li>registrar@vbu.ac.in</li>
											<li>vbuexamform@gmail.com</li>
										</ul>
									</td>
								</tr>
							</div>
						</div>
					</div>
				</div>

				<div className="ContactFooter" data-aos="fade-up">
					<div className="AdmissionEnquiry" data-aos="fade-up">
						<div className="EnquiryHeading" data-aos="fade-up">
							<div>
								<h3>Admission Enquiry</h3>
								<span className="border-bottom"></span>
							</div>
						</div>
						<div className="EnquiryForm" data-aos="fade-up">
							<form encType="multipart/form-data" onSubmit={contactHandler}>
								<div className="FirstRow" data-aos="fade-up">
									<input
										type="text"
										name="name"
										placeholder="Your Name"
										required
									/>
									<input
										type="email"
										name="email"
										placeholder="Your Email-id"
										required
									/>
								</div>
								<div className="SecondRow" data-aos="fade-up">
									<input
										type="text"
										name="number"
										pattern="\d{10}"
										maxLength={10}
										placeholder="Your Mobile No."
										required
									/>
									<select name="course" required>
										<option value="">Choose Course</option>
										<option value="BCA">BCA</option>
										<option value="MCA">MCA</option>
									</select>
								</div>
								<div className="ThirdRow" data-aos="fade-up">
									<textarea
										placeholder="Write About Your Query!"
										name="msg"
										maxLength={200}
										required
									></textarea>
								</div>
								<div
									className="SecondRow"
									data-aos="fade-up"
									style={{ dispaly: "flex", justifyContent: "flex-start" }}
								>
									<LoadCanvasTemplate style={{ display: "flex" }} />
									<input
										type="text"
										name="captcha"
										className="capthca_box"
										placeholder="Enter Captcha Here"
										required
									/>
								</div>
								<input type="submit" value="Submit" data-aos="fade-up" />
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default ContactUs;
