import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CoursesData from "../content.json";
function Courses() {
  const navigate = useNavigate();

  const gotoTop = ()=>{
    window.scrollTo({top:0,left:0,behavior:"smooth"});
  }

  return (
    <>
      <div className="CoursesContainer">
        <div id="Heading" data-aos="fade-up">
          <h1>Courses</h1>
        </div>
        <div className="CoursesCardRow">
          {Object.entries(CoursesData.Courses).map((Courses) => {
            return (
              <div className="CoursesCard" data-aos="fade-up">
                <div className="CoursesCardTop">
                  <img src={Courses[1].course_img} alt="courses-img" />
                </div>
                <div className="CoursesCardMiddle">
                  <div className="CoursesCardMiddle-Left">
                    <h4 className="course-name">{Courses[1].course_name}</h4>
                    <p className="course-duration">
                      {Courses[1].course_duration}
                    </p>
                  </div>
                  <div className="CoursesCardMiddle-Right">
                    <h4 className="course-fee">
                      &#8377;{Courses[1].course_fee}
                    </h4>
                    <p className="course-seat">{Courses[1].course_seat}</p>
                  </div>
                </div>

                <div className="CoursesCardBottom">
                  <div className="CoursesButton">
                    <Link to={Courses[1].course_link} >
                    <button onClick={gotoTop} className="Courses-btn">View Details</button>
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Courses;
