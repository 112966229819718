import React from "react";
import { Helmet } from "react-helmet";

function DirectorMessage() {
  return (
    <>
      <Helmet>
        <title>Director Message</title>
      </Helmet>
      <div className="DirectorMessageContainer">
        <div id="Heading" data-aos="fade-up">
          <h1>Director's Message</h1>
        </div>
        <div className="DirectorMessageHeader">
          <div className="DirectorMessageLeft" data-aos="fade-up">
            <p>
              <b>Welcome to Vinoba Bahve University</b>
            </p>
            <br />
            <p>
              I am pleased to welcome you to Vinoba Bhave University,
              Hazaribagh, a premier institution of higher learning in Jharkhand.
              We are committed to providing our students with a good education
              that will prepare them for success in their chosen careers.
            </p>
          </div>
          <div className="DirectorMessageRight" data-aos="fade-left">
            <img src="Images/director.jpg" alt="director-img" />
          </div>
        </div>

        <div className="DirectorMessageMiddle">
          <p data-aos="fade-up">
            Vinoba Bhave University is a vibrant and diverse community. We
            welcome students from all backgrounds and cultures. We are committed
            to creating an environment where all students can learn and grow.
          </p>
          <br />
          <p data-aos="fade-up">
            Our faculty is made up of highly qualified and experienced academics
            who are passionate about teaching and research. We are also
            committed to providing our students with the resources they need to
            succeed, including state-of-the-art laboratories, libraries, and
            other facilities.
          </p>
          <br />
          <p data-aos="fade-up">
            Our university offers a wide range of undergraduate and postgraduate
            programs in a variety of disciplines, including science,
            engineering, technology, humanities, and social sciences. We are
            also home to a number of research centers and institutes, which
            provide our students with the opportunity to conduct cutting-edge
            research.
          </p>
          <br />
          <p data-aos="fade-up">
            In addition to our academic programs, we also offer a variety of
            extracurricular activities, including sports and student
            organizations. These activities provide our students with the
            opportunity to develop their leadership skills, make new friends,
            and have fun.
          </p>
          <br />
          <p data-aos="fade-up">
            I am confident that you will find your time at Vinoba Bhave
            University to be both challenging and rewarding. We are committed to
            providing our students with the resources and support they need to
            succeed.
          </p>
          <br />
          <p data-aos="fade-up">
            I encourage you to get involved in our university community and to
            take advantage of all that we have to offer. I look forward to
            seeing you on campus!
          </p>
          <br />
        </div>
        <div className="DirectorMessageFooter">
          <div className="Signature" data-aos="fade-up">
            Dr. Ashish Kr. Saha
            <br />
            Director
          </div>
        </div>
      </div>
    </>
  );
}

export default DirectorMessage;
