import React, { useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { Link, NavLink } from "react-router-dom";
import { MdCall, MdEmail } from "react-icons/md";
import { RiMenu3Fill } from "react-icons/ri";
function Navbar() {
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => {
    if (window.innerWidth <= 769) {
      setTimeout(() => {
        document.getElementById("NavItems").classList.remove("menu-open");
        document.getElementById("NavItems").classList.add("menu-close");
        setTimeout(() => {
          document.querySelector("#NavItems").style.display = "none";
          setIsActive(!isActive);
        }, 400);
        document.querySelector("body").classList.remove("transparent-body");
      }, 200);
    }
  };

  const [isActive, setIsActive] = useState(true);
  const gotoTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const hoverProgram = () => {
    document.querySelector(".NavProgram").style.display = "flex";
  };
  const hoverOutProgram = () => {
    document.querySelector(".NavProgram").style.display = "none";
  };
  const hoverCampus = () => {
    document.querySelector(".NavCampus").style.display = "flex";
  };
  const hoverOutCampus = () => {
    document.querySelector(".NavCampus").style.display = "none";
  };
  const hoverAdministration = () => {
    document.querySelector(".NavAdministration").style.display = "flex";
  };
  const hoverOutAdministration = () => {
    document.querySelector(".NavAdministration").style.display = "none";
  };
  const hoverAlumni = () => {
    document.querySelector(".NavAlumni").style.display = "flex";
  };
  const hoverOutAlumni = () => {
    document.querySelector(".NavAlumni").style.display = "none";
  };
  const hoverAdmission = () => {
    document.querySelector(".NavAdmission").style.display = "flex";
  };
  const hoverOutAdmission = () => {
    document.querySelector(".NavAdmission").style.display = "none";
  };

  return (
    <>
      <header>
        <div className="Header">
          <div className="HeaderLeft">
            <div className="vbulogo">
              <Link to="/" onClick={gotoTop}>
                <img src="../Images/vbu_logo.png" alt="vbulogo" />
              </Link>
            </div>
            <div className="HeaderName">
              <h1>University Department of Computer Application</h1>
              <p>Vinoba Bhave University, Hazaribagh</p>
            </div>
            <div className="Menu">
              <span>
                {isActive ? (
                  <RiMenu3Fill
                    size="28px"
                    onClick={() => {
                      document.querySelector("#NavItems").style.display =
                        "flex";
                      document.querySelector("#NavItems").style.backdropFilter =
                        "grayscale(100%)";
                      setIsActive(!isActive);
                      document
                        .querySelector("body")
                        .classList.add("transparent-body");

                      setTimeout(() => {
                        document
                          .getElementById("NavItems")
                          .classList.remove("menu-close");
                        document
                          .getElementById("NavItems")
                          .classList.add("menu-open");
                      }, 100);
                    }}
                  />
                ) : (
                  <RxCross2
                    size="28px"
                    onClick={() => {
                      document
                        .querySelector("body")
                        .classList.remove("transparent-body");

                      document
                        .getElementById("NavItems")
                        .classList.remove("menu-open");
                      document
                        .getElementById("NavItems")
                        .classList.add("menu-close");
                      setTimeout(() => {
                        document.querySelector("#NavItems").style.display =
                          "none";
                        setIsActive(!isActive);
                      }, 400);
                    }}
                  />
                )}
              </span>
            </div>
          </div>
          <div className="HeaderRight">
            {/* <div className="phone-no">
							<div>
								<MdCall className="header-icon" />
							</div>

							<div className="phone-no-desc">
								<span>Phone</span>
								<span>7667479088</span>
							</div>
						</div> */}

            <div className="email-id">
              <div>
                <MdEmail className="header-icon" />
              </div>

              <Link to="mailto:vbumca@gmail.com">
                {" "}
                <div className="email-id-desc">
                  <span>Email</span>
                  <span>vbumca@gmail.com</span>
                </div>
              </Link>
            </div>
            <div
              className="login-btn"
              onClick={() => {
                window.open("https://console.vbumca.in", "_blank");
              }}
            >
              <input type="button" value="Login" name="login" />
            </div>
          </div>
        </div>
      </header>
      <nav id="nav">
        <div className="" id="navbar">
          <div className="NavbarContainer">
            {/* <div className="transparent"> */}
            <ul id="NavItems" className="menu-close">
              <NavLink
                to="/"
                onClick={() => {
                  gotoTop();
                  toggle();
                }}
              >
                <li>
                  {" "}
                  <span>Home</span>
                </li>
              </NavLink>

              <li onMouseOver={hoverProgram} onMouseOut={hoverOutProgram}>
                <div className="NavItem">
                  <span>Program</span>
                  <span className="downArrow">
                    <MdKeyboardArrowDown />
                  </span>
                </div>

                <div className="NavSubItems">
                  <ul className="NavProgram">
                    <NavLink
                      to="/BCA"
                      onClick={() => {
                        gotoTop();
                        toggle();
                      }}
                    >
                      <li>BCA</li>
                    </NavLink>
                    <NavLink
                      to="/MCA"
                      onClick={() => {
                        gotoTop();
                        toggle();
                      }}
                    >
                      <li>MCA</li>
                    </NavLink>
                  </ul>
                </div>
              </li>

              <li onMouseOver={hoverCampus} onMouseOut={hoverOutCampus}>
                <div className="NavItem">
                  <span>Campus</span>
                  <span className="downArrow">
                    <MdKeyboardArrowDown />
                  </span>
                </div>

                <div className="NavSubItems ">
                  <ul className="NavCampus">
                    <NavLink
                      to="/library"
                      onClick={() => {
                        gotoTop();
                        toggle();
                      }}
                    >
                      <li>Library</li>
                    </NavLink>

                    <NavLink
                      to="/centralLibrary"
                      onClick={() => {
                        gotoTop();
                        toggle();
                      }}
                    >
                      <li>Central Library</li>
                    </NavLink>

                    <NavLink
                      to="/ground"
                      onClick={() => {
                        gotoTop();
                        toggle();
                      }}
                    >
                      <li>Play Ground</li>
                    </NavLink>

                    {/* <NavLink
                      to="/digitalClass"
                      onClick={() => {
                        gotoTop();
                        toggle();
                      }}
                    >
                      <li>Digital Classroom</li>
                    </NavLink> */}

                    <NavLink
                      to="/auditorium"
                      onClick={() => {
                        gotoTop();
                        toggle();
                      }}
                    >
                      <li>Auditorium</li>
                    </NavLink>

                    {/* <NavLink
                      to="/gym"
                      onClick={() => {
                        gotoTop();
                        toggle();
                      }}
                    >
                      <li>GYM</li>
                    </NavLink> */}
                    <NavLink
                      to="/solarPower"
                      onClick={() => {
                        gotoTop();
                        toggle();
                      }}
                    >
                      <li>Solar Power Campus</li>
                    </NavLink>
                    {/* <NavLink
                      to="/botanicalGarden"
                      onClick={() => {
                        gotoTop();
                        toggle();
                      }}
                    >
                      <li>Botanical Garden</li>
                    </NavLink> */}

                    <NavLink
                      to="/park"
                      onClick={() => {
                        gotoTop();
                        toggle();
                      }}
                    >
                      <li>Park</li>
                    </NavLink>

                    {/* <NavLink
                      to="/canteen"
                      onClick={() => {
                        gotoTop();
                        toggle();
                      }}
                    >
                      <li>Canteen</li>
                    </NavLink> */}
                  </ul>
                </div>
              </li>

              <NavLink
                to="/research"
                onClick={() => {
                  gotoTop();
                  toggle();
                }}
              >
                <li>
                  <span>Research</span>
                </li>
              </NavLink>

              <li
                onMouseOver={hoverAdministration}
                onMouseOut={hoverOutAdministration}
              >
                <div className="NavItem">
                  <span>Administration</span>
                  <span className="downArrow">
                    <MdKeyboardArrowDown />
                  </span>
                </div>

                <div className="NavSubItems">
                  <ul className="NavAdministration">
                    <NavLink
                      to="/directorMessage"
                      onClick={() => {
                        gotoTop();
                        toggle();
                      }}
                    >
                      <li>Director Message</li>
                    </NavLink>
                    <NavLink
                      to="/teachingMembers"
                      onClick={() => {
                        gotoTop();
                        toggle();
                      }}
                    >
                      <li>Teaching Staff</li>
                    </NavLink>
                    <NavLink
                      to="/nonTeachingMembers"
                      onClick={() => {
                        gotoTop();
                        toggle();
                      }}
                    >
                      <li>Non Teaching Staff</li>
                    </NavLink>
                  </ul>
                </div>
              </li>

              <li onMouseOver={hoverAlumni} onMouseOut={hoverOutAlumni}>
                <div className="NavItem">
                  <span>Alumni</span>
                  <span className="downArrow">
                    <MdKeyboardArrowDown />
                  </span>
                </div>

                <div className="NavSubItems">
                  <ul className="NavAlumni">
                    <NavLink
                      to="/AlumniList"
                      onClick={() => {
                        gotoTop();
                        toggle();
                      }}
                    >
                      <li>Alumni List</li>
                    </NavLink>
                    <NavLink
                      to="/alumniForm"
                      onClick={() => {
                        gotoTop();
                        toggle();
                      }}
                    >
                      <li>Alumni Form</li>
                    </NavLink>
                  </ul>
                </div>
              </li>

              {/* <NavLink to="/alumniForm"  onClick={()=>{
                      gotoTop() 
                      toggle()
                    }}>
                <li>
                  <span>Alumni</span>
                </li>
              </NavLink> */}

              <NavLink
                to="/gallery"
                onClick={() => {
                  gotoTop();
                  toggle();
                }}
              >
                <li>
                  <span> Gallery </span>
                </li>
              </NavLink>

              <li onMouseOver={hoverAdmission} onMouseOut={hoverOutAdmission}>
                <div className="NavItem">
                  <span>Admission</span>
                  <span className="downArrow">
                    <MdKeyboardArrowDown />
                  </span>
                </div>

                <div className="NavSubItems">
                  <ul className="NavAdmission">
                    <NavLink
                      to="/admissionProcess"
                      onClick={() => {
                        gotoTop();
                        toggle();
                      }}
                    >
                      <li>Process</li>
                    </NavLink>
                    <NavLink
                      to="/feeStructure"
                      onClick={() => {
                        gotoTop();
                        toggle();
                      }}
                    >
                      <li>Fee Structure</li>
                    </NavLink>
                  </ul>
                </div>
              </li>
              <NavLink
                to="/about"
                onClick={() => {
                  gotoTop();
                  toggle();
                }}
              >
                <li>
                  <span>About</span>
                </li>
              </NavLink>
              <NavLink
                to="/contact"
                onClick={() => {
                  gotoTop();
                  toggle();
                }}
              >
                <li>
                  <span>Contact</span>
                </li>
              </NavLink>

              <div
                className="login-btn-mobile"
                onClick={() => {
                  window.open("https://console.vbumca.in", "_blank");
                }}
              >
                <input type="button" value="Login" name="login" />
              </div>
            </ul>
          </div>
        </div>
        {/* </div> */}
      </nav>
    </>
  );
}

export default Navbar;
