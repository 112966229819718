import "./App.css";
import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { HashRouter, Routes, Route } from "react-router-dom";

import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Home from "./Pages/Home";
import BCA from "./Pages/BCA";
import MCA from "./Pages/MCA";
import Error from "./Components/Error";
import TeachingMembers from "./Pages/TeachingMembers";
import NonTeachingMembers from "./Pages/NonTeachingMembers";
import Auditorium from "./Pages/Campus/Auditorium";
import BotanicalGardern from "./Pages/Campus/BotanicalGarden";
import Canteen from "./Pages/Campus/Canteen";
import CentralLibrary from "./Pages/Campus/CentralLibrary";
import DigitalClass from "./Pages/Campus/DigitalClass";
import Ground from "./Pages/Campus/Ground";
import Gym from "./Pages/Campus/Gym";
import Library from "./Pages/Campus/Library";
import Park from "./Pages/Campus/Park";
import SolarPower from "./Pages/Campus/SolarPower";
import DirectorMessage from "./Pages/DirectorMessage";
import Gallery from "./Pages/Gallery";
import AboutUs from "./Pages/AboutUs";
import ContactUs from "./Pages/ContactUs";
import AdmissionProcess from "./Pages/AdmissionProcess";
import TopBtn from "./Components/TopBtn";
import Alumni from "./Pages/Alumni";
import Research from "./Pages/Research";
import AlumniLIst from "./Pages/AlumniLIst";
import FeeStructure from "./Pages/FeeStructure";
import NoticeAll from "./Pages/NoticeAll";

function App() {
	// document.querySelector("body").
	useEffect(() => {
		Aos.init({ duration: 1500 });
	}, []);

	return (
		<>
			<HashRouter>
				<Navbar />
				<Routes>
					<Route exact path="/" element={<Home />} />
					<Route exact path="/teachingMembers" element={<TeachingMembers />} />
					<Route
						exact
						path="/nonTeachingMembers"
						element={<NonTeachingMembers />}
					/>
					<Route exact path="/directorMessage" element={<DirectorMessage />} />
					<Route exact path="/allNotice" element={<NoticeAll />} />
					<Route exact path="*" element={<Error />} />
					<Route exact path="/BCA" element={<BCA />} />
					<Route exact path="/MCA" element={<MCA />} />
					<Route exact path="/research" element={<Research />} />

					<Route exact path="/auditorium" element={<Auditorium />} />
					<Route exact path="/botanicalGarden" element={<BotanicalGardern />} />
					<Route exact path="/canteen" element={<Canteen />} />
					<Route exact path="/centralLibrary" element={<CentralLibrary />} />
					<Route exact path="/digitalClass" element={<DigitalClass />} />
					<Route exact path="/ground" element={<Ground />} />
					<Route exact path="/gym" element={<Gym />} />
					<Route exact path="/library" element={<Library />} />
					<Route exact path="/park" element={<Park />} />
					<Route exact path="/solarPower" element={<SolarPower />} />

					<Route exact path="/alumniForm" element={<Alumni />} />
					<Route exact path="/alumniList" element={<AlumniLIst />} />
					<Route
						exact
						path="/admissionProcess"
						element={<AdmissionProcess />}
					/>
					<Route exact path="/feeStructure" element={<FeeStructure />} />
					<Route exact path="/gallery" element={<Gallery />} />
					<Route exact path="/about" element={<AboutUs />} />
					<Route exact path="/contact" element={<ContactUs />} />
				</Routes>
				<Footer />
			</HashRouter>

			<TopBtn />
		</>
	);
}

export default App;
