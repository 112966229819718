import React from "react";
import HeroSection from "../Components/HeroSection";
import { GiCheckMark } from "react-icons/gi";
import { Helmet } from "react-helmet";

function AboutUs() {
  return (
		<>
			<Helmet>
				<title>About Us</title>
			</Helmet>
			<div className="PageContainer">
				<section>
					<HeroSection />
				</section>

				<section>
					<div className="AboutUs">
						<div id="Heading" data-aos="fade-up">
							<h1>About Us</h1>
						</div>

						<div className="AboutDesc" data-aos="fade-up">
							<p data-aos="fade-up">
								Vinoba Bhave University was established in the year 1992 with
								it’s headquarter at Hazaribag, the divisional headquarter of
								North Chhotanagpur division of the state of JHARKHAND. It is
								situated at a distance of 100 K.M. from Ranchi, The capital of
								Jharkhand. Vinoba Bhave University is imparting post-graduate
								teaching and research in Physical Science, Life Science, Earth
								Science, Social Science, Humanities, Commerce, Technology and
								Medical Science. Beside 22 colleges are managed and maintained
								by the university, including St. Columba’s College, Hazaribag
								one of the oldest and well established college in the Eastern
								India(Estd. 1899). Seventy affiliated colleges are also
								imparting teaching up to undergraduate level in all the above –
								mentioned subjects and in law, Education, Homeopathy and
								Ayurvedic Medicines. Every year, 900 B.Tech, 4000 graduate in
								the general courses, 550 graduates in professional and
								vocational courses and 500 post graduate including M.Tech.
								Degree are being awarded. Since it’s inception more than 400
								Ph.D. degree have been awarded in all the above – mentioned
								subjects, including technology. The dynamic faculty of the
								University has completed the research work of the national &
								international requirement in time.
							</p>
							<br />

							<p data-aos="fade-up">
								The Vinoba Bhave University is located at Hazaribagh, India and
								is funded by Government of India. The university came into
								existence on 17 September 1992 after the bifurcation of Ranchi
								University as a teaching-cum-affiliating university. Being among
								one of the premier universities of Jharkhand, it offers high
								standards of teaching and research. The university offers
								courses at the undergraduate and post-graduate levels.
							</p>
							<br />

							<p data-aos="fade-up">
								The Governor of Jharkhand is the chancellor of Vinoba Bhave
								University. The university has a distinguished alumni body and
								faculty. This university is also a member of Association of
								Commonwealth Universities, London. The UGC, New Delhi recognized
								the university and registered it under section 12B of the UGC
								Act 1956.
							</p>
							<br />

							<p data-aos="fade-up">
								Vinoba Bhave University is a well-respected university in
								Jharkhand. It is known for its high quality of education and its
								commitment to social service. The university has produced many
								distinguished alumni, including politicians, scientists, and
								engineers.
							</p>
							<br />

							<p data-aos="fade-up">
								<b>
									Here are some of the key facts about Vinoba Bhave University:
								</b>
							</p>
							<br />
						</div>

						<div className="AboutList" data-aos="fade-up">
							<ul>
								<table>
									<tr>
										<li data-aos="fade-up">
											<td>
												<GiCheckMark className="check-icon" />
											</td>
											<td>Established in 1992</td>
										</li>
									</tr>
									<tr>
										<li data-aos="fade-up">
											<td>
												<GiCheckMark className="check-icon" />
											</td>
											<td>Located in Hazaribagh, Jharkhand, India</td>
										</li>
									</tr>
									<tr>
										<li data-aos="fade-up">
											<td>
												<GiCheckMark className="check-icon" />
											</td>
											<td>
												Affiliated to the University Grants Commission (UGC)
											</td>
										</li>
									</tr>
									<tr>
										<li data-aos="fade-up">
											<td>
												<GiCheckMark className="check-icon" />
											</td>
											<td>
												Recognized by the National Assessment and Accreditation
												Council (NAAC)
											</td>
										</li>
									</tr>
									<tr>
										<li data-aos="fade-up">
											<td>
												<GiCheckMark className="check-icon" />
											</td>
											<td>
												Offers undergraduate and postgraduate programs in a
												variety of disciplines
											</td>
										</li>
									</tr>
									<tr>
										<li data-aos="fade-up">
											<td>
												<GiCheckMark className="check-icon" />
											</td>
											<td>Has a sprawling campus of over 1000 acres</td>
										</li>
									</tr>
									<tr>
										<li data-aos="fade-up">
											<td>
												<GiCheckMark className="check-icon" />
											</td>
											<td>
												Well-equipped with modern facilities, such as a library,
												laboratories, and hostels
											</td>
										</li>
									</tr>
									<tr>
										<li data-aos="fade-up">
											<td>
												<GiCheckMark className="check-icon" />
											</td>
											<td>
												Well-equipped with modern facilities, such as a library,
												laboratories, and hostels
											</td>
										</li>
									</tr>
									<tr>
										<li data-aos="fade-up">
											<td>
												<GiCheckMark className="check-icon" />
											</td>
											<td>
												Known for its high quality of education and its
												commitment to social service
											</td>
										</li>
									</tr>
									<tr>
										<li data-aos="fade-up">
											<td>
												<GiCheckMark className="check-icon" />
											</td>
											<td>Has produced many distinguished alumni</td>
										</li>
									</tr>
								</table>
							</ul>
						</div>
					</div>
				</section>
			</div>
		</>
	);
}

export default AboutUs;
