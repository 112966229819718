import React, { useEffect } from "react";

function Error() {
	
	return (
		<>
			<div className="Error">
				<h1>Page Not Found</h1>
			</div>
		</>
	);
}

export default Error;
