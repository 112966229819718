import React from 'react'
import {BiSolidArrowToTop,BiSolidArrowFromTop} from "react-icons/bi";

function TopBtn() {
    const goToTopWindow = ()=>{
        window.scrollTo({top:0,left:0,behavior:"smooth"})
    }
  return (
   <>
   <aside className="TopBtn" >
   <button onClick={goToTopWindow}>
    <BiSolidArrowToTop className='Btn'/>
   </button>
   </aside>
   
   </>
  )
}

export default TopBtn