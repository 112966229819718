import React from "react";
import Data from "../content.json";
import { MdMore } from "react-icons/md";
import { FaHandPointRight } from "react-icons/fa";
import { Helmet } from "react-helmet";

function TeachingMembers() {
	return (
		<>
			<Helmet>
				<title>Teaching Member</title>
			</Helmet>
			<div className="MembersContainer">
				<div id="Heading">
					<h1>Teaching Members</h1>
				</div>

				<div className="Members">
					{Object.entries(Data.teachingMembers).map((teachingData) => {
						console.log(teachingData[1]);

						return (
							<div className="MemberCard" data-aos="fade-up">
								<div className="TopMemberCard">
									<img src={teachingData[1].img} alt="memberImg1" />
									<h2>{teachingData[1].name}</h2>
									<h4>{teachingData[1].role}</h4>
								</div>
								<div className="MiddleMemberCard">
									<table>
										<tr>
											<span className="MiddleMemberCardFirstP">
												<td>
													<FaHandPointRight className="tickIcon" />
												</td>
												<td>
													<p>{teachingData[1].qualification}</p>
												</td>
											</span>
										</tr>

										{/* <tr>
                      <p>
                        <td>
                          <FaHandPointRight className="tickIcon" />
                        </td>
                        <td>{teachingData[1].Specialization}</td>
                      </p>
                    </tr> */}

										<tr>
											<span className="MiddleMemberCardFirstP">
												<td>
													<FaHandPointRight className="tickIcon" />
												</td>
												<td>
													<p>{teachingData[1].email}</p>
												</td>
											</span>
										</tr>
									</table>
								</div>
								<div className="BottomMemberCard">
									<a href={teachingData[1].resume}>
										<button className="resumebtn">
											More Details &nbsp;
											<MdMore size={"20px"} />
										</button>
									</a>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
}

export default TeachingMembers;
