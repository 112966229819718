import React from "react";
import { Link } from "react-router-dom";
import TopBtn from "../Components/TopBtn";
function Footer() {
  const gotoTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const currentYear = new Date().getFullYear();

  return (
    <>
      <TopBtn />
      <div id="Footer">
        <div className="FooterContainer">
          <div className="FooterLeft">
            <img src="/Images/vbu_logo.png" alt="logo" />
            <h4>Department of Computer Application</h4>
            <p>Vinoba Bhave University, Hazaribagh</p>
          </div>
          <div className="FooterMiddle">
            <div className="FooterMiddleLeft">
              <ul>
                <Link to="/BCA" onClick={gotoTop}>
                  <li>BCA</li>
                </Link>
                <Link to="/MCA" onClick={gotoTop}>
                  <li>MCA</li>
                </Link>
                <Link to="/teachingMembers" onClick={gotoTop}>
                  <li>Teaching Staff</li>
                </Link>
                <Link to="/nonTeachingMembers" onClick={gotoTop}>
                  <li>Non Teaching Staff</li>
                </Link>
                <Link to="" onClick={gotoTop}>
                  <li>Facility</li>
                </Link>
              </ul>
            </div>
            <div className="FooterMiddleRight">
              <ul>
                <Link to="/allNotice" onClick={gotoTop}>
                  <li>Notice</li>
                </Link>
                <Link to="" onClick={gotoTop}>
                  <li>Events</li>
                </Link>
                <Link to="/library" onClick={gotoTop}>
                  <li>Library</li>
                </Link>
                <Link to="/gallery" onClick={gotoTop}>
                  <li>Gallery</li>
                </Link>
                <Link to="/contact" onClick={gotoTop}>
                  <li>Contact</li>
                </Link>
                <Link to="/about" onClick={gotoTop}>
                  <li>About</li>
                </Link>
              </ul>
            </div>
          </div>
          <div className="FooterRight">
            <div class="mapouter">
              <div class="gmap_canvas">
                <iframe
                  style={{ height: "100%", width: "100%" }}
                  frameborder="0"
                  src="https://www.google.com/maps/embed/v1/place?q=VBU+MCA+Department,+Vinoba+Bhave+University+Road,+Sindoor,+Hazaribagh,+Jharkhand,+India&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
                ></iframe>
              </div>
            </div>
          </div>
        </div>

        <div className="FooterCopyRight">
          <span>
            &copy;&nbsp;CopyRight <span id="data">{currentYear}</span> All
            Rights Reserved. Design and Developed by &nbsp;
            <Link to="https://techiesgateway.com" target="_blank">
              <b>TechiesGateway</b>
            </Link>
          </span>
        </div>
      </div>
    </>
  );
}

export default Footer;
