import React from "react";
import { Link } from "react-router-dom";
import Courses from "../content.json";
import { GiCheckMark } from "react-icons/gi";

function BCA_MCA() {
  return (
    <>
      <div className="BCA_MCA_Container" data-aos="fade-up">
        {Object.entries(Courses.Courses).map((course) => {
          // console.log(course[1]);
          return (
            <div className="CourseContainer" data-aos="fade-up">
                <h4 className="course-title" data-aos="fade-up">{course[1].course_name}</h4>
              
              <div className="Course">
                <div className="CourseDesc" data-aos="fade-up">
                  <p>{course[1].course_desc}</p>
                  <div className="Course-seat" >
                    <span><GiCheckMark className="check-icon" />Seat : </span>
                    <span>{course[1].course_seat}</span>
                  </div>
                  <div className="Course-fee" >
                    <span><GiCheckMark className="check-icon" />Fees : </span>
                    <span>{course[1].course_fee_per_sem}</span>
                  </div>
                  <div className="ReadMore" >
                    <Link to="/BCA">Read More</Link>
                  </div>
                </div>
                <div className="CourseImg" data-aos="fade-up">
                  <img src={course[1].course_img} alt="course-img" />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default BCA_MCA;
