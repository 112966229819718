import React from "react";
import FeeStructureTable from "../Components/FeeStructureTable";
import Courses from "../Components/Courses";
import { Helmet } from "react-helmet";

function FeeStructure() {
  return (
		<>
			<Helmet>
				<title>Fee Structure</title>
			</Helmet>
			<div className="PageContainer">
				<div className="AdmissionHeader HeaderImg">
					<img src="/Images/Banner/Fees_Structure.png" alt="admission-banner" />
				</div>
				<section className="FeeStructureContainer">
					<div id="Heading" data-aos="fade-up">
						<h1>Fee Structure MCA</h1>
					</div>
					<div className="FeeStructureSection" d>
						<p data-aos="fade-up">
							The Master of Computer Applications (MCA) program at the Vinoba
							Bhave University, Jharkhand has a total fee of ₹1.51 lakhs for the
							academic year 2023-2024. The tuition fees are ₹68,100 per year,
							and there are also development fees, library fees, sports fees,
							and a security deposit. There are a few scholarships available to
							eligible students, which can help to reduce the cost of the
							program.
						</p>

						<img src={"/Images/Banner/BCA_small-banner.png"} alt="banner" />
					</div>
					{/* FEE STRUCTURE TABLE */}
					{/* MCA FEE sturcture Table section */}
					<section className="FeeStructureTable">
						<table data-aos="fade-up">
							<thead>
								<tr>
									<th>Description</th>
									<th>Amount</th>
								</tr>
							</thead>

							<tr data-aos="fade-up">
								<td>1. Tuition Fee </td>
								<td>Rs 21,000.00</td>
							</tr>

							<tr data-aos="fade-up">
								<td>2. Registration Fee</td>
								<td>Rs 1,000.00</td>
							</tr>
							<tr data-aos="fade-up">
								<td>3. Library Fee, Teaching Aids, Student Facility</td>
								<td>Rs 2,000.00</td>
							</tr>
							<tr data-aos="fade-up">
								<td>4. Internal Exam Fees</td>
								<td>Rs 2,000.00</td>
							</tr>
							<tr data-aos="fade-up">
								<td>5. General Mantainance Fees</td>
								<td>Rs 2,000.00</td>
							</tr>
							<tr data-aos="fade-up">
								<td>6. University Exam Fees</td>
								<td>Rs 2,050.00</td>
							</tr>
							<tfoot>
								<tr data-aos="fade-up">
									<td>Grand Total (Per Sem)</td>
									<td>Rs 30,050.00</td>
								</tr>
							</tfoot>
						</table>
						<article>
							<table data-aos="fade-up">
								<p
									style={{ color: "#0038A3", fontWeight: "600" }}
									data-aos="fade-up"
								>
									Fee at the time of Admission
								</p>
								<tr data-aos="fade-up">
									<td>1. Admission Fee (Payable Only Once)</td>
									<td>Rs. 5000.00</td>
								</tr>
								<tr data-aos="fade-up">
									<td>2. Caution Money (Refundable)</td>
									<td>Rs. 3000.00</td>
								</tr>
								<tr data-aos="fade-up">
									<td>
										<b>Total Fee at the Time of Admission</b>
									</td>
									<td>
										<b>Rs. 38,050.00</b>
									</td>
								</tr>
							</table>
						</article>
					</section>
				</section>
				{/* Banner Image  */}
				<aside className="clg-admisson" data-aos="fade-left">
					<img src="/Images/Banner/clg.png" alt="clg-banner" />
				</aside>

				<section className="FeeStructureContainer">
					<div id="Heading" data-aos="fade-up">
						<h1>Fee Structure BCA</h1>
					</div>
					<div className="FeeStructureSection">
						<p data-aos="fade-up">
							The Master of Computer Applications (MCA) program at the Vinoba
							Bhave University, Jharkhand has a total fee of ₹1.51 lakhs for the
							academic year 2023-2024. The tuition fees are ₹68,100 per year,
							and there are also development fees, library fees, sports fees,
							and a security deposit. There are a few scholarships available to
							eligible students, which can help to reduce the cost of the
							program.
						</p>

						<img src={"/Images/Banner/BCA_small-banner.png"} alt="banner" />
					</div>

					{/* MCA FEE sturcture Table section */}
					<section className="FeeStructureTable">
						<table data-aos="fade-up">
							<thead>
								<tr>
									<th>Description</th>
									<th>Amount</th>
								</tr>
							</thead>

							<tr data-aos="fade-up">
								<td>1. Admission Fee </td>
								<td>Rs 1,000.00</td>
							</tr>

							<tr data-aos="fade-up">
								<td>2. Registration Fee</td>
								<td>Rs 1,000.00</td>
							</tr>
							<tr data-aos="fade-up">
								<td>3. Library Fee</td>
								<td>Rs 1,000.00</td>
							</tr>
							<tr data-aos="fade-up">
								<td>4. Caution Money (Refundable)</td>
								<td>Rs. 3000.00</td>
							</tr>
							<tr data-aos="fade-up">
								<td>5. Tution Fees (Per Sem)</td>
								<td>Rs 20,000.00</td>
							</tr>

							<tfoot>
								<tr data-aos="fade-up">
									<td>Grand Total At the Time of Admission</td>
									<td>Rs 26,500.00</td>
								</tr>
							</tfoot>
						</table>
						<article>
							<table data-aos="fade-up">
								<tr data-aos="fade-up">
									<td>1. University Exam Fee</td>
									<td>Rs. 1,500.00</td>
								</tr>
								<tr data-aos="fade-up">
									<td>2. Rest 05 Semesters (per Sem)</td>
									<td>Rs. 20,000.00</td>
								</tr>
							</table>
						</article>
					</section>
				</section>
				<Courses />
			</div>
		</>
	);
}

export default FeeStructure;
