import React from "react";
import { Helmet } from "react-helmet";

function CentralLibrary() {
  return (
		<>
			<Helmet>
				<title>VBU-Central Library</title>
			</Helmet>
			<div className="PageContainer">
				<div className="Campus">
					<div className="BannerImg HeaderImg">
						<img
							src="/Images/Campus/Central Library/library-hero.jpg"
							alt="banenr-img"
						/>
					</div>

					<div id="Heading">
						<h1>Central Library</h1>
					</div>

					<div className="Desc">
						<div className="DescParagraph">
							<p>
								Sure, here is a short note on a central library of a university:
								A central library of a university is the main library of the
								university. It is typically located in the central campus and is
								the largest and most comprehensive library in the university
								system. The central library houses a wide range of resources,
								including books, journals, magazines, newspapers, electronic
								databases, and multimedia materials. It also offers a variety of
								services, such as reference assistance, research support,
								computer access, and study spaces.
								<br />
								<br />
								The central library is an important resource for students,
								faculty, and staff of the university. It provides access to a
								wide range of resources and services that can help people with
								their academic, professional, and personal goals.
							</p>
						</div>
						<div className="DescImg">
							<img src="/Images/Campus/Central Library/cl-3.jpg" alt="img1" />
						</div>
					</div>

					<div className="PhotosSection">
						<div id="Secondary_Heading">
							<h3>Photos</h3>
						</div>

						<div className="PhotoContainer">
							<div className="PhotoRow">
								<div className="Img1">
									<img
										src="/Images/Campus/Central Library/cl.jpg"
										alt="imgdinner"
									/>
								</div>
								<div className="Img2">
									<img
										src="/Images/Campus/Central Library/cl-4.jpg"
										alt="imgdinner"
									/>
								</div>
							</div>
							<div className="PhotoRow">
								<div className="Img1">
									<img
										src="/Images/Campus/Central Library/centralLib.jpg"
										alt="imgdinner"
									/>
								</div>
								<div className="Img2">
									<img
										src="/Images/Campus/Central Library/cl-3.jpg"
										alt="imgdinner"
									/>
								</div>
							</div>
							<div className="PhotoRow">
								<div className="Img3">
									<img
										src="/Images/Campus/Central Library/cl-4.jpg"
										alt="imgdinner"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default CentralLibrary;
