import React from 'react'
import { Helmet } from "react-helmet";

function BotanicalGarden() {
  return (
		<>
			<Helmet>
				<title>VBU-Garden</title>
			</Helmet>
			<div className="PageContainer">
				<div className="Campus">
					<div className="BannerImg HeaderImg">
						<img src="/Images/Campus/Canteen/frontpic.png" alt="banenr-img" />
					</div>

					<div id="Heading">
						<h1>Botanical Garden</h1>
					</div>

					<div className="Desc">
						<div className="DescParagraph">
							<p>
								A botanical garden of a university is a garden dedicated to the
								collection, cultivation, preservation, and display of plants.
								Botanical gardens are often run by universities or other
								scientific research organizations, and often have associated
								herbaria and research programmes in plant taxonomy or some other
								aspect of botanical science.
								<br />
								<br />
								Botanical gardens of universities are an important part of the
								academic community. They provide a valuable resource for
								students, researchers, and the public.
							</p>
						</div>
						<div className="DescImg">
							<img src="/Images/Campus/Canteen/drinks.png" alt="img1" />
						</div>
					</div>

					<div className="PhotosSection">
						<div id="Secondary_Heading">
							<h3>Photos</h3>
						</div>

						<div className="PhotoContainer">
							<div className="PhotoRow">
								<div className="Img1">
									<img
										src="/Images/Campus/Canteen/dinner.png"
										alt="imgdinner"
									/>
								</div>
								<div className="Img2">
									<img
										src="/Images/Campus/Canteen/drinks.png"
										alt="imgdinner"
									/>
								</div>
							</div>
							<div className="PhotoRow">
								<div className="Img1">
									<img src="/Images/Campus/Canteen/lunch.png" alt="imgdinner" />
								</div>
								<div className="Img2">
									<img
										src="/Images/Campus/Canteen/breakfast.png"
										alt="imgdinner"
									/>
								</div>
							</div>
							<div className="PhotoRow">
								<div className="Img3">
									<img
										src="/Images/Campus/Canteen/snacks.png"
										alt="imgdinner"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default BotanicalGarden